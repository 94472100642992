@font-face {
  font-family: telegraf;
  src: url("./../fonts/telegraf/Telegraf-Regular.ttf");
  font-weight: normal;
}
@font-face {
  font-family: telegraf;
  src: url("./../fonts/telegraf/Telegraf-Bold.ttf");
  font-weight: bold;
}
@font-face {
  font-family: telegraf;
  src: url("./../fonts/telegraf/Telegraf-Medium.ttf");
  font-weight: 500;
}
@font-face {
  font-family: telegraf;
  src: url("./../fonts/telegraf/Telegraf-Light.ttf");
  font-weight: 300;
}
@font-face {
  font-family: telegraf;
  src: url("./../fonts/telegraf/Telegraf-SemiBold.ttf");
  font-weight: 600;
}
*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: inherit;
  outline: none;
}

html {
  font-size: 10px;
  scroll-behavior: smooth;
}

body {
  background-color: black;
  box-sizing: border-box;
}

button {
  cursor: pointer;
}

input[type=text] {
  font-family: telegraf;
  font-size: 1.6rem;
}

.lds-facebook {
  display: inline-block;
  position: relative;
  width: 5rem;
  height: 8rem;
}

.lds-facebook div {
  display: inline-block;
  position: absolute;
  width: 1rem;
  background: white;
  animation: lds-facebook 1.2s cubic-bezier(0, 0.5, 0.5, 1) infinite;
}

.lds-facebook div:nth-child(1) {
  left: 0;
  animation-delay: -0.24s;
}

.lds-facebook div:nth-child(2) {
  left: 2rem;
  animation-delay: -0.12s;
}

.lds-facebook div:nth-child(3) {
  left: 4rem;
  animation-delay: 0;
}

@keyframes lds-facebook {
  0% {
    top: 0.8rem;
    height: 6.4rem;
  }
  50%, 100% {
    top: 2.4rem;
    height: 3.2rem;
  }
}
.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  height: 11rem;
  color: white;
}
.navbar__logo {
  -o-object-fit: contain;
     object-fit: contain;
  width: 100%;
  height: 100%;
}
.navbar__logo--placeholder {
  color: white;
  font-size: 2rem;
  text-transform: uppercase;
  font-family: telegraf;
}
.navbar__logo-container {
  padding: 2rem 0;
  margin-right: auto;
  width: 19.1rem;
  text-decoration: none !important;
}
.navbar__content {
  display: flex;
  align-items: center;
}
.navbar__links {
  display: flex;
  align-items: center;
}
.navbar__links-item {
  margin-right: 2rem;
}
.navbar__link:link, .navbar__link:visited, .navbar__link:hover, .navbar__link:active {
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.8rem;
  text-decoration: none;
  color: white;
  margin-right: 2rem;
}
.navbar__registration-btn {
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.8rem;
}
.navbar__content {
  display: flex;
  align-items: center;
}
.navbar__logout-btn {
  border: none;
  background-color: transparent;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 3rem;
  text-decoration: underline;
  color: white;
  text-transform: uppercase;
}
.navbar__welcome-message {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin-left: auto;
  margin-right: 3rem;
}
.navbar__welcome-name {
  font-weight: bold;
}

.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  padding: 4rem;
  background-color: black;
  border-radius: 4px;
  width: 73rem;
  display: flex;
  justify-content: center;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
}
.popup__close {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  top: 1.5rem;
  right: 1.5rem;
  cursor: pointer;
  z-index: 50;
}
.popup__close path {
  fill: white;
}
.popup__body {
  width: 100%;
}
.popup__error-message {
  padding: 2rem 0;
  font-weight: 500;
  font-size: 2rem !important;
}

.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  -webkit-backdrop-filter: blur(5px);
          backdrop-filter: blur(5px);
  z-index: 11;
  background-color: rgba(229, 229, 229, 0.5);
}
.overlay--dark {
  background-color: rgba(0, 0, 0, 0.9);
}

.registration-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
}
.registration-form--login {
  width: 30rem;
  margin: auto;
}
.registration-form__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
  margin-bottom: 2rem;
  align-self: flex-start;
}
.registration-form__label {
  position: relative;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 2rem;
  margin-bottom: 3px;
  display: inline-block;
  color: white;
}
.registration-form__label--required::after {
  content: "*";
  color: red;
  position: absolute;
  font-size: 2.5rem;
}
.registration-form__input {
  height: 100%;
  width: 100%;
  padding: 0 2.4rem 0 1rem;
  border: 1px solid white;
  border-radius: 2px;
}
.registration-form__input--error {
  border: 1px solid red;
}
.registration-form__select-input {
  font-family: telegraf;
  font-size: 1.6rem;
}
.registration-form__select-input__value-container {
  padding: 0px 8px !important;
}
.registration-form__select-input__input-container {
  margin: 0px !important;
}
.registration-form__input-group {
  position: relative;
  margin-bottom: 1.5rem;
  width: 100%;
}
.registration-form__input-group--half {
  width: calc(50% - 0.5rem);
}
.registration-form__input-group--double {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.registration-form__input-group--double .registration-form__input {
  width: 100%;
}
.registration-form__input-group--double .registration-form__input-group:not(:first-child) {
  margin-left: 1rem;
}
.registration-form__input-group--double .registration-form__input-group {
  width: 100%;
}
.registration-form__input-error-message {
  position: absolute;
  bottom: -2rem;
}
.registration-form__btn {
  min-width: 15rem;
  margin-top: 3rem;
}
.registration-form__redirect {
  margin-top: 2rem;
  font-family: telegraf;
  font-style: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
}
.registration-form__redirect--paragraph {
  font-weight: normal;
  color: white;
}
.registration-form__redirect--btn {
  font-weight: 500;
  color: white;
  cursor: pointer;
}
.registration-form__forgot-password {
  color: white;
  margin-top: 0;
  align-self: flex-start;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.registration-form__input-icon {
  position: absolute;
  right: 1rem;
  top: 50%;
  transform: translateY(-50%);
  width: 1.5rem;
}
.registration-form__input-box {
  position: relative;
  height: 3rem;
}
.registration-form__form-error {
  align-self: flex-start;
}
.registration-form__lock-paragraph {
  margin-top: 4rem;
  text-align: center;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.5rem;
  color: white;
}
.registration-form__lock-warning {
  text-align: center;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.5rem;
  color: white;
  margin-bottom: 2rem;
}
.registration-form__new-user-message {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.5rem;
  text-align: center;
  color: white;
  width: 40rem;
  margin-left: 50%;
  transform: translateX(-50%);
}
.registration-form__new-user-email {
  font-weight: bold;
}

input:disabled {
  background-color: hsl(0, 0%, 70%);
  color: rgb(70, 70, 70);
}

.PhoneInput {
  border: 1px solid white;
  border-radius: 2px;
  height: 100%;
  background-color: white;
}
.PhoneInput--error {
  border: 1px solid red;
}

.PhoneInputCountry {
  padding-left: 1rem;
}

.PhoneInputInput {
  height: 2.5rem;
  width: 28rem;
  padding: 0 1rem;
  border: none;
}

.PhoneInputCountrySelect--focus {
  border: none;
}

.PhoneInputCountryIcon--border {
  background-color: transparent;
  box-shadow: unset !important;
}

.error-message {
  color: red;
  font-family: telegraf;
  font-size: 1.4rem;
}

.btn {
  text-transform: uppercase;
  border-radius: 12px;
  border: none;
  padding: 1.5rem;
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.8rem;
}
.btn--primary {
  background-color: white;
  color: black;
}
.btn--secondary {
  background-color: transparent;
  color: white;
  border: 1px solid white;
}
.btn--disabled {
  background-color: #f5f5f5;
  color: white;
}

.featured {
  width: 100%;
  position: relative;
  background: linear-gradient(to top, black 15%, rgba(0, 0, 0, 0.03) 25%), linear-gradient(to bottom, black -39.64%, rgba(0, 0, 0, 0.03) 20.75%);
  height: calc(100vh - 11rem);
  height: calc((var(--vh, 1vh) * 100) - 11rem);
}
.featured__image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  z-index: -1;
  position: relative;
}
.featured__body {
  position: absolute;
  left: 7.5rem;
  bottom: 20%;
  max-height: 65%;
  max-width: calc(100% - 35rem);
  overflow: hidden;
  text-align: left;
}
.featured__heading {
  display: inline-block;
}
.featured__heading:link, .featured__heading:active, .featured__heading:hover, .featured__heading:visited {
  text-decoration: none;
}
.featured__time {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 3rem;
  color: white;
  text-transform: capitalize;
}
.featured__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  font-size: 8rem;
  line-height: 7.7rem;
  color: white;
  margin: 3rem 0;
}
.featured__artist-title {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: white;
  margin-bottom: 1.5rem;
  width: 40%;
}
.featured__artist-name {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: white;
  margin-bottom: 3rem;
  width: 40%;
}
.featured__location {
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  font-size: 2.5rem;
  color: white;
  margin-bottom: 3rem;
}
.featured__description {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 2.8rem;
  color: white;
  width: 40%;
  /* display: -webkit-box;
  line-clamp: 8;
  -webkit-line-clamp: 8;
  -webkit-box-orient: vertical;
  overflow: hidden; */
}
.featured__action {
  position: absolute;
  bottom: 20%;
  right: 7.5rem;
  background: transparent;
  border: 3px solid white;
  text-transform: uppercase;
  border-radius: 50%;
  width: 18rem;
  height: 18rem;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1.3rem;
  color: black;
  background-color: white;
}
.featured__action--disabled {
  background-color: #f5f5f5;
  border: 3px solid #f5f5f5;
  color: black;
  cursor: default;
}
.featured__action:hover {
  color: white;
  background-color: black;
  border: 3px solid black;
}
.featured__action--disabled:hover {
  background-color: #f5f5f5;
  border: 3px solid #f5f5f5;
  color: black;
  cursor: default;
}
.featured__link-to-events:link, .featured__link-to-events:visited, .featured__link-to-events:hover, .featured__link-to-events:active {
  position: absolute;
  display: flex;
  flex-direction: column;
  align-items: center;
  bottom: 4%;
  left: 50%;
  transform: translateX(-50%);
  text-transform: uppercase;
  text-decoration: none;
  color: white;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2rem;
}
.featured__scroll-icon {
  width: 3.5rem;
  height: 4.9rem;
  margin-bottom: 1rem;
  display: block;
}
.featured__more-info, .featured__more-info:link, .featured__more-info:active, .featured__more-info:hover {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 2.2rem;
  line-height: 2.8rem;
  color: white;
  padding-left: 1rem;
}

.stepper {
  display: flex;
  width: 70%;
  margin: auto;
  height: 10rem;
  align-items: center;
  position: relative;
}
.stepper__step {
  display: flex;
  align-items: center;
  color: white;
  font-family: telegraf;
}
.stepper__step--active {
  color: white;
}
.stepper__step--completed {
  color: white;
}
.stepper__step--completed .stepper__step-bar {
  background-color: white;
}
.stepper__step--with-bar {
  flex: 1;
}
.stepper__step-label {
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.9rem;
  white-space: nowrap;
  padding: 0 1.2rem;
}
.stepper__step-bar {
  width: 100%;
  height: 0.4rem;
  background-color: white;
}

.event-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 11rem - 10rem);
  height: calc(
    (var(--vh, 1vh) * 100) - 11rem - 10rem
  );
}
.event-page__body {
  display: flex;
  flex: 1;
  padding: 0 4rem;
}
.event-page--event-details {
  position: relative;
  display: block;
  height: 100%;
}
.event-page__info {
  width: 60%;
  align-items: flex-start;
  /* border-right: 1px solid $color-grey-1; */
  /* padding: 4rem 4rem 0 4rem; */
  display: flex;
  position: relative;
  justify-content: center;
  height: calc(
      100vh - 11rem - 10rem - 10rem
    );
  height: calc(
      (var(--vh, 1vh) * 100) - 11rem - 10rem - 10rem
    );
}
.event-page__info--details-page {
  height: 100%;
  width: 100%;
  padding: 0;
  border: none;
}
.event-page__guests {
  width: 60%;
  height: calc(
      100vh - 11rem - 10rem - 10rem
    );
  height: calc(
      (var(--vh, 1vh) * 100) - 11rem - 10rem - 10rem
    );
  overflow-y: auto;
  padding-bottom: 2rem;
}
.event-page__event-image-area {
  position: relative;
  width: 100%;
  height: 100%;
  background: linear-gradient(180.29deg, rgba(0, 0, 0, 0) 30.82%, rgba(0, 0, 0, 0.7) 64.93%);
}
.event-page__event-image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  position: relative;
  z-index: -1;
}
.event-page__event-image-text {
  position: absolute;
  color: white;
  z-index: 3;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  bottom: 15rem;
  left: 10rem;
  width: 40%;
}
.event-page__event-image-date, .event-page__event-image-location {
  margin-bottom: 2rem;
  font-size: 3rem;
  line-height: 3.5rem;
}
.event-page__event-image-title {
  font-size: 5rem;
  line-height: 5rem;
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  margin-bottom: 4rem;
  color: white;
}
.event-page__description {
  width: 40%;
  padding: 1rem 2rem 1rem 2rem;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  border-radius: 6px;
  height: calc(
      98vh - 11rem - 10rem - 10rem
    );
  height: calc(
      (var(--vh, 1vh) * 98) - 11rem - 10rem - 10rem
    );
  overflow-y: auto;
}
.event-page__description--details-page {
  border-radius: 4px;
  height: 80%;
  height: unset;
  background-color: rgba(0, 0, 0, 0.8);
  -webkit-backdrop-filter: blur(20px);
          backdrop-filter: blur(20px);
  margin-right: 0 !important;
  padding: 5rem;
  width: 47%;
  position: absolute;
  top: 3rem;
  right: 6%;
  bottom: 15rem;
  color: white;
}
.event-page__description--padded, .event-page__info--padded {
  padding-bottom: 2rem;
}
.event-page__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 2.5rem;
  color: white;
}
.event-page__title--ticket-selector {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.event-page__paragraph {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 2.8rem;
  margin-bottom: 4rem;
}
.event-page__event-day-details {
  display: flex;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.8rem;
}
.event-page__event-day-detail-col {
  flex: 1;
}
.event-page__event-day-detail-title {
  margin-bottom: 3rem;
}
.event-page__btn {
  width: 20rem;
  margin-top: 4rem;
  color: black !important;
}
.event-page__layout-image-area {
  position: relative;
  height: 100%;
  width: 100%;
}
.event-page__layout-image {
  -o-object-fit: contain;
     object-fit: contain;
  height: 100%;
  width: 100%;
}
.event-page__ticket-subheader {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.event-page__ticket-max-warning {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2rem;
  color: white;
  flex: 1;
}
.event-page__ticket-clear {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.4rem;
  text-transform: uppercase;
  color: white;
  cursor: pointer;
  height: 1.7rem;
  margin-top: 1rem;
}
.event-page__ticket-clear-icon {
  width: 2.6rem;
  height: 1.7rem;
  margin-right: 1rem;
}
.event-page__ticket-clear-icon path {
  fill: white;
}
.event-page__ticket-area {
  color: white;
}
.event-page__ticket-area--selected {
  border-bottom: 1px solid white;
  font-weight: bold;
}
.event-page__ticket-area--not-selected {
  border-bottom: 1px solid #c5c5c5;
}
.event-page__ticket-row {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  cursor: pointer;
}
.event-page__ticket-row > * {
  flex: 1;
}
.event-page__ticket-name {
  font-family: telegraf;
  font-style: normal;
  font-weight: 400;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.event-page__ticket-color {
  display: inline-block;
  margin-right: 1rem;
  width: 2rem;
  height: 2rem;
  border-radius: 50%;
}
.event-page__ticket-dropdown-icon {
  width: 2.2rem;
  height: 1rem;
  cursor: pointer;
  transition: transform 0.2s;
}
.event-page__ticket-dropdown-icon--expanded {
  transform: rotateX(180deg);
}
.event-page__ticket-dropdown-icon path {
  fill: white;
}
.event-page__ticket-dropdown-icon-wrapper {
  display: flex;
  flex-basis: 2.2rem;
  flex-grow: 0;
  justify-content: flex-end;
  height: 100%;
  align-items: center;
}
.event-page__ticket-state {
  font-family: telegraf;
  font-weight: bold;
  font-size: 2rem;
}
.event-page__ticket-name-wrapper--expired {
  text-decoration: line-through;
  color: #666666;
}
.event-page__ticket-counter {
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-page__ticket-price {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
}
.event-page__ticket-count {
  width: 5rem;
  text-align: center;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
}
.event-page__ticket-counter-icon {
  border: none;
  background-color: transparent;
  display: flex;
  height: 2rem;
  width: 2rem;
  align-items: center;
}
.event-page__ticket-counter-icon path {
  fill: white;
}
.event-page__ticket-description {
  font-family: telegraf;
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.8rem;
  transition: padding-top 0.2s;
  height: 0;
  overflow: hidden;
}
.event-page__ticket-description--expanded {
  padding: 5rem 3rem;
  height: unset;
}
.event-page__event-image-btns {
  height: 14rem;
  display: flex;
  justify-content: space-between;
}
.event-page__event-image-btns--desktop {
  flex-direction: column;
  margin-top: 3rem;
}
.event-page__event-image-btns--mobile {
  display: none;
}

.labeled-btn-container {
  display: flex;
}

.labeled-btn {
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
}
.labeled-btn__icon {
  width: 5.5rem;
  height: 5.5rem;
}
.labeled-btn__icon--share circle {
  stroke: white;
}
.labeled-btn__icon--share path {
  fill: white;
}
.labeled-btn__icon--directions path:nth-child(1),
.labeled-btn__icon--directions path:nth-child(3),
.labeled-btn__icon--directions path:nth-child(4) {
  stroke: white;
}
.labeled-btn__icon--directions path:nth-child(2),
.labeled-btn__icon--directions path:nth-child(4) {
  fill: white;
}
.labeled-btn__text {
  padding-left: 2rem;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
}

.share-list {
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateY(10%);
  background-color: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
}
.share-list__close-icon {
  position: absolute;
  top: 0.75rem;
  right: 0.75rem;
  width: 1.25rem;
  height: 1.25rem;
  cursor: pointer;
}
.share-list__btn {
  margin-right: 1rem;
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.share-list__btn:last-child {
  margin-right: 0;
}

.cta-popup {
  height: 10rem;
  width: 100%;
  background-color: black;
  color: white;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  position: fixed;
  bottom: 0;
  z-index: 7;
}
.cta-popup__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
  color: white;
}
.cta-popup__text-area {
  flex: 1;
}
.cta-popup__warning {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.8rem;
  text-transform: uppercase;
  color: red;
  margin-bottom: 1rem;
}
.cta-popup__display-message {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
}
.cta-popup__timer {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
}
.cta-popup__timer .countdown {
  color: white;
}
.cta-popup__btn-box {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}
.cta-popup__error-btn {
  display: flex;
  justify-content: center;
}
.cta-popup__error-btn--cancel {
  margin-right: 2rem;
}
.cta-popup__btn {
  display: flex;
  align-items: center;
  width: 35.8rem;
  height: 6rem;
}
.cta-popup__btn--disabled {
  background-color: #c5c5c5;
}
.cta-popup__btn .btn-line {
  width: 1px;
  height: 2.5rem;
  border-right: 2px solid black;
}
.cta-popup__btn--1, .cta-popup__btn--2 {
  flex: 1;
}
.cta-popup__btn--width-icon {
  width: 35.8rem;
  display: flex;
  align-items: center;
  justify-content: center;
}
.cta-popup__arrow-icon {
  width: 3.4rem;
  height: 2.4rem;
  margin-left: 3rem;
}
.cta-popup__arrow-icon path {
  fill: black;
}

.event-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba(255, 255, 255, 0.2);
  -webkit-backdrop-filter: blur(4px);
          backdrop-filter: blur(4px);
  z-index: 2;
}

.countdown {
  display: flex;
  color: white;
}
.countdown__time, .countdown__separator, .countdown__expired {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
}
.countdown__time--danger, .countdown__separator--danger, .countdown__expired--danger {
  color: red;
}
.countdown__label {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
}

.registration-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem 2rem 4rem 2rem;
  border-bottom: 1px solid #c4c4c4;
}
.registration-header__info {
  color: white;
}
.registration-header__timer {
  display: flex;
  width: 14rem;
  height: 14rem;
  justify-content: center;
  align-items: center;
}
.registration-header__timer svg path:nth-child(2) {
  stroke: white;
}
.registration-header__tickets {
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid white;
  border-radius: 100px;
  padding: 2rem;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 3rem;
  color: white;
}
.registration-header__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2rem;
  margin-top: 2rem;
}
.registration-header__icon {
  margin-right: 2rem;
}
.registration-header__icon path {
  fill: white;
}

.checkout__payment, .checkout__details {
  overflow-y: auto;
}
.checkout__payment {
  width: 60%;
  height: calc(
      100vh - 11rem - 10rem - 10rem
    );
  height: calc(
      (var(--vh, 1vh) * 100) - 11rem - 10rem - 10rem
    );
  padding: 4rem 12rem 2rem 4rem;
}
.checkout__details {
  padding: 1rem 2rem;
  box-shadow: 0px 4px 10px 10px rgba(0, 0, 0, 0.03);
  border-radius: 6px;
  width: 40%;
  height: calc(
      98vh - 11rem - 10rem - 10rem
    );
  height: calc(
      (var(--vh, 1vh) * 98) - 11rem - 10rem - 10rem
    );
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
}
.checkout__details .receipt-table tbody tr:last-child td {
  border: none;
}
.checkout__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 2rem;
}
.checkout__subtitle {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
  position: relative;
  display: inline-block;
}
.checkout__subtitle--required::after {
  content: "*";
  color: red;
  position: absolute;
  font-size: 2.5rem;
}
.checkout__paragraph {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-top: 2rem;
  margin-bottom: 2rem;
  color: white;
}
.checkout__subparagraph {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: #666666;
  margin-bottom: 1rem;
}
.checkout__receipt .checkout__grand-total {
  display: none;
}
.checkout__receipt-toggle {
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-radius: 100px;
  padding: 2rem;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 2.5rem;
  line-height: 2.5rem;
  border: 1px solid white;
  color: white;
}
.checkout__receipt-toggle--opened {
  border: 1px solid white;
  color: white;
}
.checkout__receipt-icon {
  width: 2rem;
  height: 1rem;
  margin-left: 2rem;
}
.checkout__grand-total {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.checkout__grand-total-title, .checkout__grand-total-value {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
  color: white;
  text-transform: uppercase;
}
.checkout__grand-total-value {
  text-align: right;
}
.checkout__grand-total-subparagraph {
  color: #666666;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  margin-top: 1rem;
  text-transform: none;
  text-align: right;
}
.checkout__mobile-collapse-bar {
  display: none;
  background-color: #bdbdbd;
  border-radius: 100px;
  margin: auto;
}
.checkout__order-actions {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  align-items: center;
}
.checkout__edit-addons {
  display: flex;
  align-items: center;
  color: white;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-align: right;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-right: 2rem;
}
.checkout__edit-guests {
  display: flex;
  align-items: center;
  color: white;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-align: right;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  margin-right: 2rem;
}
.checkout__edit-addons-icon, .checkout__cancel-order-icon, .checkout__edit-guests-icon {
  width: 2.4rem;
  height: 2.4rem;
  margin-right: 0.5rem;
}
.checkout__edit-addons-icon path, .checkout__cancel-order-icon path, .checkout__edit-guests-icon path {
  fill: white;
}
.checkout__receipt {
  margin-top: 5rem;
}
.checkout__cancel-order {
  display: inline-block;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  color: white;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  cursor: pointer;
  display: flex;
  align-items: center;
}
.checkout__cancel-order-icon {
  width: 1.4rem;
  height: 1.4rem;
}
.checkout__cancel-order-icon path {
  fill: white;
}
.checkout__toggle {
  display: none;
}

.addons-list {
  padding-bottom: 2rem;
}

.addon {
  width: 100%;
  padding: 3rem 0;
  border-bottom: 1px solid #c5c5c5;
}
.addon--selected {
  border-bottom: 1px solid white;
}
.addon--selected .addon__name, .addon--selected .addon__price {
  font-weight: bold;
}
.addon__body {
  display: flex;
  align-items: center;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.addon__base {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
}
.addon__info {
  flex: 1;
}
.addon__name {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: white;
  cursor: pointer;
}
.addon__price {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: white;
  margin-top: 1rem;
}
.addon__counter {
  display: flex;
  align-items: center;
  flex: 1;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
}
.addon__counter-icon-wrapper {
  width: 1.5rem;
  height: 1.5rem;
  display: flex;
  align-items: center;
  margin-right: 1rem;
  margin-left: 1rem;
  cursor: pointer;
}
.addon__counter-icon-wrapper path {
  fill: white;
}
.addon__counter-icon {
  width: 100%;
}
.addon__dropdown-icon {
  cursor: pointer;
}
.addon__dropdown-icon--opened {
  transform: rotate(180deg);
}
.addon__dropdown-icon path {
  fill: white;
}
.addon__description {
  margin-top: 1rem;
  margin-left: 3.5rem;
  font-family: telegraf;
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.8rem;
  color: white;
}

.payment-method {
  padding: 3rem 0;
  border-bottom: 1px solid #c5c5c5;
  cursor: pointer;
}
.payment-method--selected {
  border-bottom: 1px solid white;
}
.payment-method__body {
  display: flex;
  align-items: center;
  cursor: pointer;
}
.payment-method__name {
  flex: 1;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2rem;
  color: white;
  text-transform: uppercase;
  /* .tooltip {
    position: relative;
    left: unset;
    margin-left: 1rem;
    text-transform: none;
    &__icon {
      width: 1.6rem;
      height: 1.6rem;
    }
    &__text-box {
      bottom: 3rem;
    }
  } */
}
.payment-method__description {
  font-size: 1.2rem;
  line-height: 1.5rem;
  font-weight: 400;
  display: inline-block;
}
.payment-method__icon {
  width: 2.4rem;
  margin-right: 1rem;
}
.payment-method__icon path {
  stroke: white;
}
.payment-method__icon--filled path {
  fill: white;
}
.payment-method__icon--image {
  width: 4rem;
}

.receipt-table {
  width: 100%;
  border-collapse: collapse;
  /* tbody tr:last-child td {
    border: none;
  } */
}
.receipt-table th:first-child {
  text-align: left;
}
.receipt-table th:last-child {
  text-align: right;
}
.receipt-table th {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  color: white;
}
.receipt-table td {
  text-align: center;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: white;
  padding: 1rem 0;
  border-bottom: 1px solid white;
  width: 33%;
}
.receipt-table tr td:first-child {
  text-align: left;
}
.receipt-table tr td:last-child {
  text-align: right;
}
.receipt-table tbody tr:first-child td {
  padding-top: 3rem;
}
.receipt-table__subtotal-tr td {
  border: none;
  padding: 3rem 0;
}
.receipt-table__subtotal-tr .receipt-table__subtotal-text {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: white;
}

* {
  /* width */
  /* Track */
  /* Handle */
  /* Handle on hover */
}
*::-webkit-scrollbar {
  width: 3px;
  margin-right: 10px;
}
*::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}
*::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 100px;
}
*::-webkit-scrollbar-thumb:hover {
  background: #666666;
}

.event-page__description,
.checkout__details,
.payment-confirmation__details {
  margin-right: 3px;
}

.highest-z-index {
  z-index: 100;
}

.success-display {
  height: 80vh;
  padding: 10rem 0;
  text-align: center;
}
.success-display__tickets {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 2rem;
}
.success-display__icon-lock {
  width: 2rem;
  margin-right: 0.75rem;
}
.success-display__event {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2rem;
  color: white;
  text-align: center;
}
.success-display__icon-check {
  width: 16rem;
  margin-bottom: 2rem;
  margin-top: 2rem;
}
.success-display__message {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 3rem;
  color: white;
  text-transform: uppercase;
}
.success-display__timer-wrapper {
  display: flex;
  justify-content: center;
}

.payment-iframe {
  width: 100%;
  max-width: 767px;
  height: 95vh;
  height: calc(var(--vh, 1vh) * 95);
  margin-left: 50%;
  transform: translateX(-50%);
}
.payment-iframe--loading {
  opacity: 0;
}

.payment-confirmation {
  display: flex;
  padding: 0 8rem;
  height: calc(100vh - 11rem);
  height: calc((var(--vh, 1vh) * 100) - 11rem);
  align-items: center;
}
.payment-confirmation__btn-container {
  display: flex;
  justify-content: flex-end;
  margin-top: 5rem;
}
.payment-confirmation__btn {
  width: 30rem;
}
.payment-confirmation--with-action-bar {
  height: calc(100vh - 11rem - 10rem);
  height: calc(
      (var(--vh, 1vh) * 100) - 11rem - 10rem
    );
}
.payment-confirmation--with-action-bar .payment-confirmation__event,
.payment-confirmation--with-action-bar .payment-confirmation__details {
  height: calc(95vh - 11rem - 10rem);
  height: calc(
        (var(--vh, 1vh) * 95) - 11rem - 10rem
      );
}
.payment-confirmation__event, .payment-confirmation__details {
  flex: 1;
  height: calc(90vh - 11rem);
  height: calc((var(--vh, 1vh) * 90) - 11rem);
}
.payment-confirmation__event {
  background: linear-gradient(180.29deg, rgba(0, 0, 0, 0) 30.82%, rgba(0, 0, 0, 0.7) 64.93%);
  position: relative;
  margin-right: 4rem;
  border-radius: 12px;
  overflow: hidden;
}
.payment-confirmation__event-image-text {
  position: absolute;
  color: white;
  z-index: 3;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  bottom: 10rem;
  left: 4rem;
}
.payment-confirmation__event-image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: -1;
}
.payment-confirmation__success-icon {
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1.5rem;
}
.payment-confirmation__error-icon {
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1.5rem;
}
.payment-confirmation__error-icon path {
  fill: red;
}
.payment-confirmation__pending-icon {
  width: 2.8rem;
  height: 2.8rem;
  margin-right: 1.5rem;
}
.payment-confirmation__pending-icon path {
  fill: #ffcc00;
}
.payment-confirmation__title-container {
  display: flex;
}
.payment-confirmation__download-icon {
  width: 2.4rem;
  height: 2.8rem;
  margin-right: 1rem;
}
.payment-confirmation__download-icon path {
  fill: white;
}
.payment-confirmation__event-image-date {
  margin-bottom: 2rem;
  font-size: 3.2rem;
  line-height: 3.5rem;
  color: white;
}
.payment-confirmation__event-image-title {
  font-style: normal;
  font-weight: 500;
  font-size: 5rem;
  line-height: 5rem;
  color: white;
  text-transform: uppercase;
}
.payment-confirmation__details {
  padding: 5rem 0;
  margin-left: 4rem;
  overflow-y: auto;
}
.payment-confirmation__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
  color: white;
  text-transform: uppercase;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 5rem;
}
.payment-confirmation__paragraph {
  font-family: telegraf;
  color: white;
  font-size: 2.3rem;
}
.payment-confirmation__status {
  font-family: telegraf;
}
.payment-confirmation__status-success {
  color: #1db954;
}
.payment-confirmation__status-error {
  color: red;
}
.payment-confirmation__status-pending {
  color: #ffcc00;
}
.payment-confirmation .checkout__receipt {
  padding-bottom: 2rem;
  margin-top: 3rem;
}
.payment-confirmation .checkout__grand-total {
  margin-bottom: 2rem;
}
.payment-confirmation__payment-method {
  display: flex;
  justify-content: space-between;
  border-top: 1px solid #c5c5c5;
  padding-top: 2rem;
}
.payment-confirmation__payment-method-status {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  color: white;
  display: flex;
  flex-direction: column;
}
.payment-confirmation__payment-method-status--success {
  color: #1db954;
}
.payment-confirmation__payment-method-status--error {
  color: red;
}
.payment-confirmation__payment-method-icon {
  width: 1.9rem;
  margin-right: 1rem;
}
.payment-confirmation__payment-method-image {
  margin-top: 2rem;
  width: 6.4rem;
}
.payment-confirmation__payment-method-details {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-end;
}
.payment-confirmation__reference-title {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  color: #666666;
}
.payment-confirmation__reference-number {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: #666666;
  margin-bottom: 1rem;
}
.payment-confirmation__download-btn {
  text-transform: uppercase;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1.8rem;
  text-decoration: none;
  display: flex;
  align-items: center;
}
.payment-confirmation__kiosk-dialog {
  background-color: #f9f9f9;
  padding: 3rem;
  border-radius: 6px;
}
.payment-confirmation__kiosk-dialog-status {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  margin-top: 2rem;
}
.payment-confirmation__kiosk-description {
  font-family: telegraf;
  font-size: 1.6rem;
  line-height: 2rem;
}
.payment-confirmation__share-and-directions {
  display: flex;
  height: 13rem;
  justify-content: space-between;
  flex-direction: column;
  margin-bottom: 2rem;
}
.payment-confirmation__fail-paragraph {
  font-family: telegraf;
  font-size: 1.6rem;
  font-weight: 500;
  color: black;
}
.payment-confirmation__fail-link, .payment-confirmation__fail-link:active, .payment-confirmation__fail-link:visited, .payment-confirmation__fail-link:hover, .payment-confirmation__fail-link:link {
  color: white;
}
.payment-confirmation__cancel-order-container {
  display: flex;
  justify-content: flex-end;
}
.payment-confirmation__cancel-order {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.8rem;
  cursor: pointer;
  display: flex;
  align-items: center;
  color: red;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.payment-confirmation__cancel-order-icon {
  width: 1.4rem;
  height: 1.4rem;
  margin-right: 0.5rem;
}
.payment-confirmation__cancel-order-icon path {
  fill: red;
}
.payment-confirmation__upgrade-dialog {
  background-color: #0f0f0f;
  padding: 3rem;
  border-radius: 6px;
}
.payment-confirmation__upgrade-dialog-title {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2.5rem;
  line-height: 2.5rem;
  color: white;
  margin-bottom: 1rem;
}
.payment-confirmation__upgrade-dialog-description {
  font-family: telegraf;
  font-size: 1.6rem;
  line-height: 2rem;
  color: white;
}
.payment-confirmation__upgrade-dialog-description--bold {
  font-weight: bold;
}

.payment-redirect {
  position: relative;
  width: 100%;
  height: calc(100vh - 11rem);
  height: calc((var(--vh, 1vh) * 100) - 11rem);
}
.payment-redirect__loader {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.page-title {
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  font-size: 5rem;
  line-height: 5rem;
  color: white;
}

.myorders {
  padding: 4rem;
}

.order-card {
  padding: 2rem 0;
  border-top: 1px solid white;
  border-bottom: 1px solid white;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
}
.order-card__image-container {
  width: 19rem;
  height: 11rem;
  margin-right: 2rem;
}
.order-card__image {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
}
.order-card__event-title {
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  color: white;
}
.order-card__event-title, .order-card__summary-price {
  font-size: 3rem;
  line-height: 3rem;
  margin-bottom: 2rem;
}
.order-card__summary-price {
  text-align: right;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  color: white;
}
.order-card__event-detail, .order-card__summary-date {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: white;
}
.order-card__event-detail:not(:first-of-type) {
  margin-left: 2rem;
}
.order-card__order-summary {
  margin-left: auto;
  margin-right: 4rem;
}
.order-card__summary-date {
  text-align: right;
}
.order-card__redirect-icon {
  width: 4rem;
}
.order-card__redirect-icon path {
  fill: white;
}
.order-card__order-status {
  font-family: telegraf;
  font-style: normal;
  font-size: 1.7rem;
  font-weight: 400;
  text-transform: uppercase;
}
.order-card__order-status--success {
  color: #1db954;
}
.order-card__order-status--error {
  color: red;
}
.order-card__order-status--pending {
  color: #ffcc00;
}

.sandwich__checkbox {
  display: none;
}
.sandwich__button {
  cursor: pointer;
  position: relative;
  z-index: 10;
  height: 3rem;
  width: 3rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.sandwich__button:hover > .sandwich__icon--1 {
  margin-bottom: 1.2rem;
}
.sandwich__icon {
  width: 3rem;
  height: 2px;
  background-color: white;
  transition: all 0.2s;
}
.sandwich__icon--1 {
  margin-bottom: 1rem;
}
.sandwich__background {
  position: fixed;
  top: 2rem;
  right: 1.5rem;
  width: 6rem;
  height: 6rem;
  border-radius: 50%;
  background-color: transparent;
  z-index: 8;
  transition: all 0.6s;
}
.sandwich__nav {
  position: fixed;
  width: 0;
  height: 100vh;
  height: calc(var(--vh, 1vh) * 100);
  top: 0;
  left: 0;
  z-index: 9;
  opacity: 0;
  visibility: hidden;
  transition: all 0.5s ease;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  overflow-y: auto;
}
.sandwich__nav-body {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
}
.sandwich__nav-list {
  list-style: none;
  margin-top: auto;
}
.sandwich__nav-item {
  margin-bottom: 4rem;
  text-align: center;
}
.sandwich__nav-link:visited, .sandwich__nav-link:link, .sandwich__nav-link:active, .sandwich__nav-link:hover {
  display: block;
  text-decoration: none;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 10rem;
  line-height: 10.5rem;
  color: white;
  transition: transform 0.3s ease-in-out;
  white-space: nowrap;
}
@supports ((-webkit-background-clip: text) or (background-clip: text)) and (background-image: linear-gradient(to right, white, #c4c4c4)) {
  .sandwich__nav-link:visited, .sandwich__nav-link:link, .sandwich__nav-link:active, .sandwich__nav-link:hover {
    background-image: linear-gradient(to right, white, #c4c4c4);
    -webkit-background-clip: text;
            background-clip: text;
    color: transparent;
  }
}
.sandwich__nav-socials {
  margin-top: 5rem;
  text-align: center;
  margin-bottom: auto;
}
.sandwich__nav-socials-paragraph {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 3rem;
  line-height: 3rem;
  color: white;
  margin-bottom: 1.5rem;
}
.sandwich__nav-socials-icon {
  width: 4rem;
}
.sandwich__nav-socials-icon path {
  fill: white;
}
.sandwich__nav-socials-link {
  display: inline-block;
}
.sandwich__nav-socials-link:not(:first-of-type) {
  margin-left: 2rem;
}
.sandwich__nav-item:hover > .sandwich__nav-link {
  transform: rotateY(180deg);
}
.sandwich__checkbox:checked ~ .sandwich__button .sandwich__icon--1 {
  transform: rotate(135deg);
  margin-bottom: -2px;
}
.sandwich__checkbox:checked ~ .sandwich__button {
  position: fixed;
  top: 4rem;
  right: 3rem;
}
.sandwich__checkbox:checked ~ .sandwich__button .sandwich__icon--2 {
  transform: rotate(-135deg);
}
.sandwich__checkbox:checked ~ .sandwich__background {
  transform: scale(80);
  background-color: black;
}
.sandwich__checkbox:checked ~ .sandwich__nav {
  opacity: 1;
  width: 100%;
  visibility: visible;
}
.sandwich__terms {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 2.5rem;
  color: black;
  margin-top: auto;
  margin-bottom: 2rem;
}
.sandwich__terms--link:active, .sandwich__terms--link:link, .sandwich__terms--link:visited, .sandwich__terms--link:hover {
  color: white;
}

.page-loader {
  position: fixed;
  z-index: 12;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
}
.page-loader__body {
  position: absolute;
  max-width: 60rem;
  text-align: center;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.page-loader__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 4rem;
  line-height: 4rem;
  color: white;
  margin-bottom: 2rem;
}
.page-loader__message {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 4rem;
  line-height: 4rem;
  color: white;
}

.expiry-popup__header {
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #c4c4c4;
}
.expiry-popup__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 2rem;
  line-height: 2rem;
  text-transform: uppercase;
  color: white;
}
.expiry-popup__body {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.expiry-popup__message, .expiry-popup__action-message {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 2rem;
  line-height: 2.5rem;
  margin-top: 3rem;
  margin-bottom: 3rem;
  text-align: center;
  color: white;
}
.expiry-popup__message--error, .expiry-popup__action-message--error {
  color: red;
  font-weight: 500;
}
.expiry-popup__action-message {
  color: white;
  margin-top: 0;
  margin-bottom: 3rem;
}
.expiry-popup__btn {
  font-family: telegraf;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4rem;
  height: 5.7rem;
  width: 38rem;
  margin-bottom: 1.5rem;
}
.expiry-popup__icon {
  display: none;
}
.expiry-popup__icon path {
  fill: red;
}

.tooltip {
  position: absolute;
  left: calc(50% - 8.3rem);
  z-index: 12;
}
.tooltip path {
  fill: white;
  stroke: black;
}
.tooltip__icon {
  width: 2.2rem;
  height: 2.2rem;
}
.tooltip__text-box {
  display: block;
  padding: 1rem;
  background-color: white;
  color: black;
  border-radius: 4px;
  width: 20rem;
  position: absolute;
  bottom: 3.3rem;
  left: -1rem;
}
.tooltip__text-box::after {
  content: "";
  display: block;
  position: absolute;
  bottom: -0.5rem;
  left: 2rem;
  width: 1rem;
  height: 1rem;
  background-color: white;
  transform: rotate(45deg);
  z-index: -1;
}
.tooltip__icon:hover ~ .tooltip__text-box {
  display: block;
}
.tooltip__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  font-size: 1.4rem;
  line-height: 2rem;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
}
.tooltip__message {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.2rem;
  line-height: 1.4rem;
}
.tooltip__close-icon {
  font-family: telegraf;
  font-size: 1.4rem;
  cursor: pointer;
  position: absolute;
  top: 1rem;
  right: 1rem;
}

.mobile-nav {
  position: fixed;
  background-color: transparent;
  left: 1rem;
  right: 1rem;
  top: 11rem;
  display: none;
}
.mobile-nav__icon {
  width: 5rem;
}
.mobile-nav__btn {
  position: absolute;
}
.mobile-nav__btn--next {
  right: 0;
  transform: rotate(180deg);
}
.mobile-nav__btn--prev {
  left: 0;
}

.events {
  padding: 5rem 3rem;
}
.events__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 5rem;
  line-height: 5rem;
  margin-bottom: 5rem;
  color: white;
}

.event-card {
  position: relative;
  display: flex;
  padding: 3rem 0;
  border-top: 1px solid white;
}
.event-card__image-container {
  flex-shrink: 0;
  width: 49.3rem;
  height: 30.7rem;
  margin-right: 3rem;
}
.event-card__image {
  -o-object-fit: contain;
     object-fit: contain;
  -o-object-position: center left;
     object-position: center left;
  width: 100%;
  height: 100%;
}
.event-card__text {
  display: flex;
  flex-direction: column;
  margin-right: 3rem;
  flex: 1;
}
.event-card__artist {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.6rem;
  color: white;
}
.event-card__event-name, .event-card__event-name:active, .event-card__event-name:visited, .event-card__event-name:link {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 3rem;
  line-height: 3rem;
  margin: 2rem 0;
  color: white;
  text-decoration: none;
  transition: color 0.2s;
}
.event-card__event-name:hover {
  color: white;
}
.event-card__event-name--disabled:hover {
  color: white;
}
.event-card__description {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 2.8rem;
  color: white;
  max-height: 16.8rem;
  overflow: hidden;
  text-overflow: ellipsis;
}
.event-card__info {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: white;
  margin-top: auto;
  margin-bottom: 2rem;
}
.event-card__location, .event-card__price {
  margin-left: 2rem;
}
.event-card__cta {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.event-card__see-more:active, .event-card__see-more:visited, .event-card__see-more:link, .event-card__see-more:hover {
  color: white;
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.4rem;
  line-height: 2.8rem;
  margin-right: 3rem;
  text-transform: uppercase;
  text-decoration: none;
}
.event-card__see-more--mobile {
  display: none;
}
.event-card__btn {
  width: 20.7rem;
  height: 6rem;
  text-decoration: none;
  display: flex;
  align-items: center;
  justify-content: center;
}
.event-card__share-area {
  position: absolute;
  right: 2rem;
  top: 3rem;
}
.event-card__share-icon {
  width: 2.9rem;
  height: 3.2rem;
  cursor: pointer;
}
.event-card .share-list {
  right: 0;
  min-width: 16rem;
}

.event-summary {
  padding: 2rem;
  background-color: #0f0f0f;
  /* box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 6px;
  margin-bottom: 2.5rem;
  display: flex;
}
.event-summary__image-container {
  flex: 0 0 19.3rem;
  height: 11.2rem;
}
.event-summary__image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}
.event-summary__body {
  margin-left: 2rem;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.event-summary__artist, .event-summary__event-name {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.8rem;
  line-height: 1.8rem;
  color: white;
}
.event-summary__event-info {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: white;
}
.event-summary__event-time {
  display: none;
}
.event-summary__action-area {
  display: flex;
}
.event-summary__action:not(:last-child) {
  margin-right: 2rem;
}
.event-summary__action, .event-summary__action:link, .event-summary__action:visited, .event-summary__action:hover, .event-summary__action:active {
  display: flex;
  align-items: center;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.4rem;
  line-height: 1.4rem;
  color: white;
  text-decoration: none;
  cursor: pointer;
  white-space: nowrap;
}
.event-summary__action-icon {
  margin-right: 0.5rem;
  flex: 0 0 2rem;
  height: 2rem;
}
.event-summary__action-icon path {
  fill: white;
  stroke: black;
}
.event-summary__action-icon circle {
  fill: white;
}
.event-summary__popup-image {
  width: 100%;
  height: 100%;
  -o-object-fit: contain;
     object-fit: contain;
}

.terms {
  color: white;
}
.terms__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.5rem;
  position: relative;
  margin: 1.5rem 0;
  color: white;
}
.terms__title::after {
  content: "*";
  color: red;
  position: absolute;
  font-size: 2rem;
}
.terms__body {
  font-family: telegraf;
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.8rem;
  max-height: 12rem;
  overflow-y: auto;
  /* Track */
  /* Handle */
  /* Handle on hover */
}
.terms__body--title {
  margin-bottom: 1rem;
}
.terms__body::-webkit-scrollbar {
  width: 3px;
  margin-right: 10px;
}
.terms__body::-webkit-scrollbar-track {
  background: transparent;
  border-radius: 100px;
}
.terms__body::-webkit-scrollbar-thumb {
  background: #a0a0a0;
  border-radius: 100px;
}
.terms__body::-webkit-scrollbar-thumb:hover {
  background: #666666;
}
.terms__btn {
  margin-top: 1rem;
  margin-bottom: 1rem;
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem !important;
  line-height: 1.6rem !important;
}
.terms .error-message {
  margin-bottom: 1rem;
}

.help-page {
  display: flex;
  height: calc(100vh - 11rem);
  height: calc((var(--vh, 1vh) * 100) - 11rem);
}
.help-page__title-container {
  padding: 5rem 5rem 0 5rem;
  flex: 1;
  position: relative;
}
.help-page__content {
  padding: 5rem 5rem 0 5rem;
  flex: 1;
  height: 100%;
  overflow-y: auto;
  padding-bottom: 2rem;
}
.help-page__title {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 10rem;
  line-height: 12rem;
  color: white;
}
@supports ((-webkit-background-clip: text) or (background-clip: text)) and (background-image: linear-gradient(to right, white, #c4c4c4)) {
  .help-page__title {
    background-image: linear-gradient(to right, white 35%, #c4c4c4 80%);
    -webkit-background-clip: text;
            background-clip: text;
    color: transparent;
  }
}

.faq {
  position: relative;
  border-bottom: 1px solid white;
  padding: 2rem 0;
  cursor: pointer;
  color: white;
}
.faq__title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.faq__question {
  font-family: telegraf;
  font-style: normal;
  font-weight: 600;
  font-size: 1.6rem;
  line-height: 2.5rem;
  width: calc(100% - 3rem);
}
.faq__answer {
  height: 0;
  overflow: hidden;
  font-family: telegraf;
  font-style: normal;
  font-weight: 300;
  font-size: 1.6rem;
  line-height: 1.8rem;
  padding-top: 0;
  transition: padding-top 0.2s;
  white-space: pre-wrap;
  color: #666666;
}
.faq__answer--expanded {
  height: unset;
  padding-top: 2rem;
}
.faq__icon {
  width: 2.1rem;
  transition: transform 0.2s;
}
.faq__icon--expanded {
  transform: rotateX(180deg);
}
.faq__icon path {
  fill: white;
}

.terms-of-purchase__paragraph {
  margin-bottom: 3rem;
}
.terms-of-purchase__paragraph--line2 {
  margin-bottom: 1.5rem;
}
.terms-of-purchase__links-list {
  list-style-position: inside;
  margin-bottom: 3rem;
}
.terms-of-purchase__link-item {
  margin-bottom: 1rem;
}
.terms-of-purchase__link {
  color: #3468c2;
  text-decoration: none;
  font-weight: 500;
  display: inline-block;
  cursor: pointer;
}
.terms-of-purchase__title {
  font-weight: 700;
  margin-bottom: 1rem;
}

.event-info-popup {
  width: 95%;
  background-color: black;
  border: 1px solid black;
}
.event-info-popup .event-page__event-image-text {
  left: 8rem;
}
.event-info-popup .cta-popup {
  display: none;
}
.event-info-popup .popup__close path {
  fill: white;
}

.mobile-header {
  display: none;
}

.guests {
  width: calc(100% - 11rem);
  position: relative;
  margin-top: 2rem;
  padding: 2rem 6rem 2rem 4rem;
  color: white;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}
.guests .guests__select-input__menu {
  color: black;
}
.guests__select-input {
  font-family: telegraf;
}
.guests__select-input--error .guests__select-input__indicators, .guests__select-input--success .guests__select-input__indicators {
  visibility: hidden;
}
.guests__select-input__menu {
  color: white !important;
}
.guests__select-input__control--is-disabled {
  background-color: hsl(0, 0%, 85%) !important;
}
.guests__select-input__control--is-disabled .guests__select-input__input-container {
  color: rgb(90, 90, 90) !important;
}
.guests__select-input__control--is-disabled .guests__select-input__single-value {
  color: rgb(90, 90, 90) !important;
}
.guests__select-input__indicator {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}
.guests .registration-form__input {
  height: 4.4rem;
}
.guests .registration-form__input-group {
  margin-bottom: 3rem;
}
.guests input:disabled,
.guests button:disabled {
  background-color: hsl(0, 0%, 85%) !important;
  color: rgb(90, 90, 90) !important;
}
.guests .PhoneInputInput {
  height: 4rem;
  font-size: 1.6rem;
  font-family: telegraf;
}
.guests .PhoneInput {
  border-radius: 0;
  height: 4.4rem;
  background-color: white;
  color: black;
}
.guests__row {
  padding-top: 1rem;
}
.guests__row .guests__expand {
  margin-left: auto;
}
.guests__row, .guests__collapsed-row {
  border-bottom: 1px solid #c5c5c5;
  position: relative;
}
.guests__title {
  font-family: telegraf;
  font-style: normal;
  font-weight: bold;
  font-size: 1.6rem;
  line-height: 2.5rem;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  cursor: pointer;
}
.guests__collapsed-row {
  display: flex;
  padding: 2rem 0;
  align-items: center;
  cursor: pointer;
}
.guests__collapsed-row .guests__title {
  margin-bottom: 0;
  width: 10rem;
}
.guests__name, .guests__ticket {
  font-family: telegraf;
  font-style: normal;
  font-weight: normal;
  font-size: 1.6rem;
  line-height: 1.9rem;
  flex: 1;
  display: flex;
  justify-content: flex-end;
  padding-left: 2rem;
}
.guests__expand-icon {
  width: 1.9rem;
}
.guests__expand-icon--expanded {
  transform: rotateX(180deg);
}
.guests__expand-icon path {
  fill: white;
}
.guests__expand {
  cursor: pointer;
  margin-left: 3rem;
}
.guests__status-icon--error path {
  fill: red;
}
.guests__status-icon--success path {
  fill: #1db954;
}
.guests__delete {
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid red;
  color: red;
  position: absolute;
  padding: 1rem 2rem;
  right: -11rem;
  font-size: 1.3rem;
  font-weight: 400;
}
.guests__add-btn {
  font-family: telegraf;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
  font-weight: 600;
  font-size: 1.7rem;
  margin-left: 4rem;
  color: white;
  cursor: pointer;
  display: inline-block;
  font-size: 2rem;
}
.guests .registration-form__input-box {
  font-size: 1.6rem;
  height: 4.4rem;
}

.forgot-password-page {
  position: relative;
  width: 50rem;
  margin: auto;
}
.forgot-password-page__form {
  display: flex;
  flex-direction: column;
}
.forgot-password-page__paragraph {
  font-size: 1.6rem;
  color: white;
  font-family: telegraf;
  margin-bottom: 2rem;
}
.forgot-password-page .registration-form__title {
  color: white;
  margin-bottom: 1rem;
}
.forgot-password-page .registration-form__label {
  color: white;
}
.forgot-password-page .registration-form__btn {
  align-self: center;
}

.success-message {
  color: #1db954;
}

.presales-page {
  display: flex;
  height: calc(100vh - 11rem - 10rem);
  height: calc(
    (var(--vh, 1vh) * 100) - 11rem - 10rem
  );
  justify-content: space-between;
}
.presales-page__error {
  font-size: 4rem;
  color: red;
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  margin-top: 2rem;
  margin-left: 2rem;
}
.presales-page__success {
  font-size: 4rem;
  font-family: telegraf;
  font-style: normal;
  font-weight: 500;
  margin-top: 2rem;
  margin-left: 2rem;
}
.presales-page__guests-area {
  width: 60%;
  overflow-y: auto;
  margin-top: 4rem;
  margin-right: 1rem;
  margin-left: 4rem;
}
.presales-page .guests {
  width: calc(100% - 13rem);
  padding-top: 0;
  margin-top: 0;
  padding-left: 0;
  padding-right: 0;
}
.presales-page .guests__row:first-child {
  padding-top: 0;
}
.presales-page .checkout__details {
  margin-top: 4rem;
  margin-right: 4rem;
}
.presales-page .checkout__subtitle {
  margin-bottom: 2rem;
}
.presales-page .page-title {
  font-family: telegraf;
  font-weight: 500;
  font-style: normal;
  margin-bottom: 2rem;
}
.presales-page .checkout__order-actions {
  display: none;
}
.presales-page .event-summary__action {
  display: none;
}
.presales-page .event-summary__event-time {
  display: none;
}
.presales-page .guests__add-btn {
  margin-left: 0;
}
.presales-page__description {
  color: white;
  font-family: telegraf;
  font-size: 2rem;
  margin-bottom: 2rem;
}
.presales-page__ticket-selector {
  width: 60%;
  overflow-y: auto;
  padding: 4rem;
}

.payment-popup {
  background-color: white;
  padding-top: 0;
  padding-bottom: 0;
}
.payment-popup .popup__close path {
  fill: black;
}

.payment-methods-list .payment-methods-list {
  padding-left: 5rem;
}

.reset-password-page {
  margin-top: 10rem;
  padding: 5rem;
  width: 60rem;
  background-color: #0f0f0f;
  border-radius: 4px;
}

.profile {
  display: flex;
  width: 80%;
  margin: auto;
  height: calc(100vh - 11rem);
  padding: 2rem 3rem;
  align-items: flex-start;
  overflow-y: auto;
}
.profile__side-bar {
  flex: 2;
  margin-right: 3rem;
  background-color: #0f0f0f;
  border-radius: 4px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.5rem 3rem;
  overflow-y: auto;
}
.profile__body {
  flex: 5;
  background-color: #0f0f0f;
  border-radius: 4px;
  padding: 3rem 5rem;
}
.profile__picture-container {
  width: 20rem;
  height: 20rem;
  margin-bottom: 2.5rem;
}
.profile__picture {
  -o-object-fit: cover;
     object-fit: cover;
  width: 100%;
  height: 100%;
  background-color: transparent;
  border-radius: 50%;
}
.profile__picture path {
  fill: #666666;
}
.profile__user-name {
  color: white;
  font-family: telegraf;
  font-size: 2.8rem;
  line-height: 2.8rem;
  font-weight: 500;
  margin-bottom: 2.5rem;
}
.profile__tabs {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.profile__tab-item {
  display: flex;
  align-items: center;
  border-radius: 100px;
  padding: 1rem;
  cursor: pointer;
  transition: all 0.3s;
}
.profile__tab-item:not(:last-child) {
  margin-bottom: 1rem;
}
.profile__tab-item--active {
  background-color: white;
}
.profile__tab-item--active .profile__tab-item-label {
  color: black;
}
.profile__tab-item--active .profile__tab-item-icon path {
  fill: black;
}
.profile__tab-item-icon-container {
  width: 3rem;
  height: 3rem;
}
.profile__tab-item-icon {
  width: 100%;
  height: 100%;
}
.profile__tab-item-icon path {
  fill: white;
}
.profile__tab-item-label {
  color: white;
  font-family: telegraf;
  font-weight: 400;
  font-size: 2.4rem;
  line-height: 2.4rem;
  margin-left: 2rem;
}
.profile__form-btn-container {
  margin-top: 2rem;
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.profile__form-btn {
  padding: 1rem 2.5rem;
}
.profile__form-message {
  font-family: telegraf;
  font-size: 1.6rem !important;
  line-height: 1.6rem !important;
  margin-right: 1rem;
}

/* Customize the label (the container) */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  cursor: pointer;
  font-size: 2.2rem;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 2rem;
  width: 2rem;
  border: 1px solid white;
  border-radius: 50%;
  background-color: white;
}

.custom-checkbox--square .checkmark {
  border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: black;
  border: 1px solid white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 50%;
  top: 50%;
  width: 0.5rem;
  height: 1rem;
  border: solid black;
  border-width: 0 1px 1px 0;
  transform: translateY(-70%) translateX(-50%) rotate(45deg);
}

.custom-radio {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  font-size: 2.2rem;
  cursor: pointer;
  -webkit-user-select: none;
     -moz-user-select: none;
          user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-radio .checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 2rem;
  width: 2rem;
  background-color: white;
  border-radius: 50%;
  border: 1px solid white;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid white;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background: black;
}

.privacy-policy {
  color: white;
  font-family: telegraf;
  padding: 2rem 4rem;
}
.privacy-policy h2 {
  font-size: 3rem;
  line-height: 5rem;
  margin-bottom: 2rem;
}
.privacy-policy p {
  font-size: 2rem;
  margin-bottom: 0.75rem;
}
.privacy-policy b {
  font-weight: 600;
}

.seat-details__button {
  padding: 1rem 1.5rem;
  border: 1px solid white;
  border-radius: 12px;
  font-size: 2rem;
  line-height: 2rem;
  display: inline-block;
  font-family: telegraf;
}
.seat-details__button-text {
  display: inline-block;
  margin-right: 2rem;
  color: white;
}
.seat-details__button-count {
  display: inline-flex;
  justify-content: center;
  align-items: center;
  width: 4rem;
  height: 4rem;
  border-radius: 50%;
  background-color: white;
  color: black;
}
.seat-details__popup {
  overflow-y: auto;
}
.seat-details__popup-header {
  border-bottom: 1px solid #c4c4c4;
  padding-bottom: 3rem;
}
.seat-details__seats-table {
  margin-top: 3rem;
}
.seat-details__seats-table tr:last-child td {
  border: none;
}
.seat-details__seats-table td {
  padding: 1.5rem 1rem;
}
.seat-details__seats-table th {
  padding-left: 1rem;
  padding-right: 1rem;
}
.seat-details__seats-delete-icon {
  width: 1.5rem;
  height: 1.5rem;
  cursor: pointer;
}
.seat-details__seats-delete-icon path {
  fill: white;
}
.seat-details__table-last-row td {
  border: none;
}

.empty-page {
  width: 100%;
  height: calc(100vh - 11rem);
  height: calc((var(--vh, 1vh) * 100) - 11rem);
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem;
}
.empty-page__message {
  font-family: telegraf;
  font-weight: 400;
  font-size: 3.2rem;
  line-height: 3.8rem;
  text-align: center;
  color: white;
}
.empty-page__link:link, .empty-page__link:visited, .empty-page__link:hover, .empty-page__link:active {
  color: white;
}

.page-loader-container {
  width: 100%;
  height: calc(100vh - 11rem);
  height: calc((var(--vh, 1vh) * 100) - 11rem);
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-selector__header {
  width: 100%;
  font-family: telegraf;
  font-size: 2rem;
  color: white;
}
.payment-selector__body {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.payment-selector__body .payment-methods-list {
  width: 100%;
  margin-bottom: 4rem;
}
.payment-selector__btn {
  font-family: telegraf;
  font-style: normal;
  font-weight: 600;
  font-size: 1.4rem;
  line-height: 1.4rem;
  height: 5.7rem;
  width: 38rem;
  margin-bottom: 2rem;
}

@media only screen and (min-width: 125em) {
  html {
    font-size: 13.2px;
  }
}
@media only screen and (max-width: 105em) {
  html {
    font-size: 9.28px;
  }
}
@media only screen and (max-width: 90em) {
  html {
    font-size: 8.8px;
  }
}
@media only screen and (max-width: 85.375em) {
  html {
    font-size: 8px;
  }
}
@media only screen and (max-width: 80em) {
  html {
    font-size: 7.36px;
  }
}
@media only screen and (max-width: 75em) {
  input[type=text],
  input[type=password] {
    font-size: 4rem;
  }
  .featured {
    background: linear-gradient(to top, black 35%, rgba(0, 0, 0, 0.03) 37%);
    overflow: hidden;
  }
  .featured__image {
    -o-object-fit: contain;
       object-fit: contain;
    height: 65%;
  }
  .featured__title {
    font-size: 9rem;
    line-height: 9rem;
    margin: 2rem 0;
  }
  .featured__body {
    position: unset;
    width: 100%;
    padding: 0 2.5rem;
    max-height: 26%;
  }
  .featured__heading {
    position: absolute;
    bottom: 35%;
    left: 2.5rem;
    max-width: calc(100% - 27rem);
  }
  .featured__artist-title {
    font-size: 3.3rem;
    line-height: 3.2rem;
    position: absolute;
    top: 3.6rem;
    left: 3rem;
  }
  .featured__artist-name {
    font-weight: 600;
    font-size: 3.7rem;
    line-height: 3.7rem;
  }
  .featured__location {
    font-size: 3.7rem;
    position: absolute;
    top: 8rem;
    left: 3rem;
  }
  .featured__description {
    font-size: 2.3rem;
    line-height: 4.2rem;
    width: 100%;
  }
  .featured__action {
    bottom: 42%;
    width: 24rem;
    height: 24rem;
    font-weight: 600;
    font-size: 2.4rem;
    line-height: 2.3rem;
    right: 2.5rem;
  }
  .featured__link-to-events:link, .featured__link-to-events:visited, .featured__link-to-events:hover, .featured__link-to-events:active {
    font-style: normal;
    font-weight: 400;
    font-size: 2.5rem;
    line-height: 2.7rem;
    bottom: 4%;
  }
  .stepper {
    width: 100%;
    padding: 2rem 2rem;
    height: unset;
  }
  .stepper::after {
    content: none;
  }
  .stepper__step {
    width: unset;
    height: 1.2rem;
    flex: 1;
    border-radius: 5px;
    border: none;
    background-color: #c4c4c4;
  }
  .stepper__step--active, .stepper__step--completed {
    background-color: white;
  }
  .stepper__step span {
    display: none;
  }
  .stepper__step:not(:last-child) {
    margin-right: 1rem;
  }
  .stepper__step-label {
    display: none;
  }
  .stepper__step-bar {
    display: none;
  }
  .event-page {
    height: calc(100vh - 31rem);
    height: calc(var(--vh, 1vh) * 100 - 31rem);
    display: flex;
    flex-direction: column;
    overflow-y: hidden;
  }
  .event-page--full-height {
    height: calc(100vh - 11rem);
    height: calc(var(--vh, 1vh) * 100 - 11rem);
  }
  .event-page__body {
    flex-direction: column;
    flex: 1;
    display: flex;
    margin-bottom: 0;
    overflow-y: auto;
    padding: 0;
  }
  .event-page--event-details .event-page__event-day-details {
    color: black;
  }
  .event-page--event-details .event-page__description {
    background-color: black;
    overflow-y: auto;
  }
  .event-page--event-details .event-page__description--details-page {
    position: relative;
    border-radius: 0;
    width: 100%;
    top: unset;
    right: unset;
    bottom: unset;
    padding: 5rem 4rem 0 4rem;
  }
  .event-page--event-details .event-page__description, .event-page--event-details .event-page__info {
    flex: 1;
  }
  .event-page--event-details .event-page__info {
    background: linear-gradient(to top, #000 0%, rgba(0, 0, 0, 0.03) 6%);
  }
  .event-page--event-details ~ .cta-popup {
    background-color: black;
  }
  .event-page--event-details ~ .cta-popup .cta-popup__timer .countdown {
    color: white;
  }
  .event-page__info {
    border: none;
    padding: 0;
    max-height: 70rem;
    width: 100%;
  }
  .event-page__description {
    margin-right: 0;
    height: unset;
    overflow-y: unset;
    width: 100%;
    box-shadow: none;
    border-radius: 0;
  }
  .event-page__description--padded {
    padding-top: 2rem;
  }
  .event-page__event-image-title {
    font-size: 5.6rem;
    line-height: 5.6rem;
  }
  .event-page__event-image-text {
    bottom: 4rem;
    left: 4rem;
    right: 4rem;
    width: unset;
  }
  .event-page__event-image-date, .event-page__event-image-location {
    font-weight: 500;
    font-size: 3.3rem;
    line-height: 3.3rem;
  }
  .event-page__event-image-btns--desktop {
    display: none;
  }
  .event-page__event-image-btns--mobile {
    display: flex;
    padding: 0 4rem;
    background-color: black;
  }
  .event-page__title {
    font-weight: normal;
    font-size: 4.2rem;
    line-height: 4.2rem;
  }
  .event-page--event-details .event-page__title {
    color: black;
  }
  .event-page__paragraph {
    color: #ccc;
    font-size: 3.25rem;
    line-height: 4.85rem;
    margin-bottom: 0;
  }
  .event-page__event-image {
    position: relative;
    z-index: -1;
  }
  .event-page__ticket-state {
    font-size: 3.5rem;
  }
  .event-page__ticket-max-warning {
    font-size: 3.2rem;
    line-height: 3.8rem;
    margin-right: 1rem;
  }
  .event-page__ticket-clear {
    font-size: 2.8rem;
    line-height: 2.8rem;
    height: 4rem;
  }
  .event-page__ticket-clear-icon {
    width: 5rem;
    height: 4rem;
  }
  .event-page__ticket-row {
    align-items: center;
  }
  .event-page__ticket-name {
    flex: 2;
    font-size: 3.7rem;
    line-height: 4.2rem;
  }
  .event-page__ticket-color {
    width: 3rem;
    height: 3rem;
    margin-right: 2rem;
  }
  .event-page__ticket-counter {
    flex: 1;
    justify-content: center;
  }
  .event-page__ticket-counter--with-state {
    justify-content: flex-end;
  }
  .event-page__ticket-counter--with-state ~ .event-page__ticket-dropdown-icon-wrapper {
    flex: 0;
  }
  .event-page__ticket-name-wrapper {
    flex: 2;
  }
  .event-page__ticket-dropdown-icon {
    width: 3.5rem;
    height: 2.5rem;
  }
  .event-page__ticket-description {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .event-page__ticket-price {
    justify-content: flex-start;
    font-size: 3.7rem;
    line-height: 3.7rem;
    margin-bottom: 2rem;
  }
  .event-page__title--ticket-selector {
    font-weight: bold;
  }
  .event-page__ticket-counter-icon {
    width: 4.5rem;
    height: 4.5rem;
  }
  .event-page__ticket-count {
    font-size: 4.8rem;
    line-height: 4.8rem;
    margin: 0 2rem;
  }
  .event-page__guests {
    width: 100%;
    height: unset;
    flex: 1;
  }
  .guests {
    width: calc(100% - 13rem);
  }
  .guests__delete {
    font-size: 2rem;
    right: -14rem;
  }
  .guests__title {
    font-size: 2.6rem;
    line-height: 2.6rem;
    font-weight: 500;
  }
  .guests__collapsed-row .guests__title {
    width: 14rem;
  }
  .guests__status-icon {
    width: 3.2rem;
  }
  .guests__name {
    font-size: 2.6rem;
    line-height: 2.6rem;
    justify-content: flex-start;
  }
  .guests__ticket {
    font-size: 2.6rem;
    line-height: 2.6rem;
  }
  .guests__expand-icon {
    width: 3.2rem;
  }
  .guests__add-btn {
    font-size: 3.7rem;
    line-height: 3.7rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .guests .registration-form__input {
    height: 8rem;
    font-size: max(3rem, 16px);
    line-height: max(3rem, 16px);
    border: 1px solid black;
  }
  .guests .registration-form__input-group {
    margin-bottom: 5rem;
  }
  .guests .registration-form__input-box {
    font-size: max(3rem, 16px);
    height: 8rem;
  }
  .guests .registration-form__input-icon {
    width: 4.2rem;
  }
  .guests .PhoneInput {
    height: 100%;
    border: 1px solid black;
  }
  .guests .PhoneInput--error {
    border: 1px solid red;
  }
  .guests .PhoneInputInput {
    height: 100%;
    background-color: unset;
    font-size: max(3rem, 16px);
  }
  .labeled-btn__icon {
    width: 6.5rem;
    height: 6.5rem;
  }
  .labeled-btn__text {
    font-size: 2.8rem;
    line-height: 2.8rem;
    font-weight: 400;
  }
  .share-list {
    bottom: 100%;
    right: 0;
    top: unset;
    padding: 3rem;
    width: 31rem;
    transform: translateY(0);
  }
  .share-list__close-icon {
    width: 2rem;
    height: 2rem;
    top: 1rem;
    right: 1rem;
  }
  .cta-popup {
    height: 20rem;
    padding: 0 2.5rem;
    color: white;
  }
  .cta-popup__title {
    display: none;
  }
  .cta-popup__btn-box {
    flex: 1;
    height: 13rem;
  }
  .cta-popup__btn-box .btn--primary {
    box-shadow: 1px 1px 11px 3px rgba(255, 255, 255, 0.5);
  }
  .cta-popup__btn--disabled {
    box-shadow: none !important;
  }
  .cta-popup__btn {
    border-radius: 2.8rem;
    font-size: 4rem;
    line-height: 4rem;
    width: 100%;
    height: 100%;
  }
  .cta-popup__btn .btn-line {
    height: 6.5rem;
  }
  .cta-popup__text-area {
    flex: unset;
  }
  .cta-popup__warning {
    font-size: 3rem;
    line-height: 3rem;
  }
  .cta-popup__display-message {
    font-size: 3rem;
    line-height: 3rem;
  }
  .cta-popup--warning {
    height: unset;
    padding: 2.5rem;
  }
  .cta-popup--warning .cta-popup__error-btn {
    font-size: 3rem;
    line-height: 3rem;
    width: 15rem;
    height: 10rem;
  }
  .cta-popup--warning .cta-popup__btn-box {
    height: 10rem;
  }
  .cta-popup--hidden {
    display: none;
  }
  .cta-popup__timer {
    flex: unset;
    width: 20rem;
    margin: 0 2rem;
  }
  .cta-popup__timer .countdown {
    color: white;
  }
  .cta-popup__arrow-icon {
    margin-left: 0;
    width: 8.4rem;
    height: 6.4rem;
  }
  .checkout__payment {
    border: none;
    padding-bottom: 0;
    overflow-y: auto;
    width: 100%;
    height: unset;
    flex: 1;
  }
  .checkout__details {
    position: relative;
    margin-right: 0;
    height: unset;
    max-height: 60%;
    height: unset;
    margin-top: 0;
    margin-right: 0;
    width: 100%;
  }
  .checkout__details--hidden {
    display: none;
  }
  .checkout__details--collapsed .event-summary {
    display: none;
  }
  .checkout__details--collapsed .checkout__grand-total {
    margin-top: 1rem !important;
  }
  .checkout__details--collapsed .checkout__receipt {
    margin-top: 0;
  }
  .checkout__details--collapsed .checkout__toggle {
    top: 0rem;
  }
  .checkout__details .receipt-table tbody tr:last-child td {
    border-bottom: 1px solid white;
  }
  .checkout__details, .checkout__payment {
    padding: 3rem 4rem;
  }
  .checkout__subtitle {
    font-size: 3.7rem;
    line-height: 3.7rem;
    display: none;
  }
  .checkout__paragraph {
    font-size: 2.8rem;
    line-height: 2.8rem;
    display: none;
  }
  .checkout__title {
    font-size: 3.25rem;
    line-height: 3.25rem;
  }
  .checkout__subparagraph {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .checkout__receipt .checkout__grand-total {
    display: flex;
    margin-top: 4rem;
  }
  .checkout__receipt .checkout__grand-total-title, .checkout__receipt .checkout__grand-total-value {
    font-weight: normal;
  }
  .checkout__receipt-toggle {
    font-size: 2.8rem;
    line-height: 2.8rem;
    height: 7rem;
    margin: 2rem 0 0 0;
  }
  .checkout__receipt-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .checkout__grand-total {
    display: none;
  }
  .checkout__grand-total-title, .checkout__grand-total-value {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
  .checkout__grand-total-subparagraph {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .checkout__details-header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 6rem;
    padding-top: 3rem;
  }
  .checkout__mobile-collapse-bar {
    display: block;
    width: 14rem;
    height: 1.4rem;
  }
  .checkout__edit-addons-icon, .checkout__edit-guests-icon {
    width: 3.6rem;
    height: 3.6rem;
  }
  .checkout__edit-addons, .checkout__edit-guests {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .checkout__order-actions {
    margin-bottom: 1rem;
    justify-content: flex-start;
  }
  .checkout__cancel-order {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .checkout__cancel-order-icon {
    width: 2rem;
    height: 2rem;
  }
  .checkout__toggle {
    display: flex;
    justify-content: flex-end;
    position: sticky;
    top: 0rem;
    z-index: 3;
  }
  .checkout__toggle-icon {
    transition: transform 0.3s;
  }
  .checkout__toggle-icon--collapsed {
    transform: rotateX(180deg);
  }
  .checkout__toggle-icon path {
    fill: white;
  }
  .terms__title {
    font-size: 2.6rem;
    line-height: 3.5rem;
  }
  .terms__title::after {
    font-size: 3.6rem;
  }
  .terms__body {
    font-size: 2.6rem;
    line-height: 2.9rem;
    max-height: 18rem;
  }
  .terms__btn {
    font-size: 2.6rem !important;
    line-height: 2.5rem !important;
  }
  .addon__name {
    font-size: 2.3rem;
    line-height: 4rem;
    font-weight: bold;
  }
  .addon__price {
    font-size: 2.3rem;
    line-height: 2.3rem;
  }
  .addon__description {
    font-size: 2.6rem;
    line-height: 2.6rem;
    font-weight: normal;
  }
  .addon__counter {
    font-size: 3.2rem;
    line-height: 3.2rem;
    justify-content: flex-end;
    margin-right: 2rem;
  }
  .addon__counter-icon-wrapper {
    width: 3.5rem;
    height: 3.5rem;
    margin: 0 2rem;
  }
  .addon__dropdown-icon {
    width: 3.25rem;
    height: 3.25rem;
  }
  .payment-method__name {
    font-size: 2.3rem;
    line-height: 2.3rem;
    font-weight: bold;
    /* .tooltip {
      &__icon {
        width: 2.8rem;
        height: 2.8rem;
      }
      &__text-box {
        bottom: unset;
        top: calc(100% + 2rem);
        &::after {
          bottom: unset;
          top: -1rem;
        }
      }
    } */
  }
  .payment-method__description {
    font-size: 2rem;
    line-height: 2rem;
  }
  .payment-method__icon {
    width: 4.65rem;
  }
  .payment-method__icon--image {
    width: 5rem;
  }
  .receipt-table th,
  .receipt-table td, .receipt-table__subtotal-tr .receipt-table__subtotal-text {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .countdown__time, .countdown__expired, .countdown__separator {
    font-size: 4.4rem;
    line-height: 4.4rem;
  }
  .countdown__label {
    font-size: 3rem;
    line-height: 3rem;
  }
  .custom-checkbox {
    padding-left: 5rem;
  }
  .custom-checkbox .checkmark {
    width: 24px;
    height: 24px;
  }
  .custom-checkbox .checkmark:after {
    width: 6px;
    height: 12px;
    border-width: 0 2px 2px 0;
  }
  .custom-radio {
    padding-left: 5rem;
  }
  .custom-radio .checkmark {
    width: 24px;
    height: 24px;
  }
  .custom-radio .checkmark:after {
    width: 16px;
    height: 16px;
  }
  .navbar__link:link, .navbar__link:visited, .navbar__link:hover, .navbar__link:active {
    font-size: 2.6rem;
    line-height: 2.6rem;
  }
  .navbar__registration-btn {
    border-radius: 2rem;
    font-size: 2.6rem;
    line-height: 2.6rem;
    padding: 1.5rem;
  }
  .navbar__logout-btn {
    font-size: 4.2rem;
    line-height: 4.2rem;
  }
  .navbar__welcome-message {
    font-size: 2rem;
    line-height: 2rem;
  }
  .popup {
    width: 100%;
    height: 100%;
    border: none;
    z-index: 51;
    border-radius: 0px;
    transform: none;
    top: unset;
    bottom: 0;
    left: 0;
  }
  .popup__close {
    width: 3rem;
    height: 3rem;
  }
  .popup__body {
    height: 100%;
  }
  .popup__body--registration {
    display: flex;
    flex-direction: column;
  }
  .registration-form {
    width: 100%;
    padding-top: 15rem;
  }
  .registration-form--register, .registration-form--login {
    overflow-y: auto;
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  .registration-form--register::-webkit-scrollbar, .registration-form--login::-webkit-scrollbar {
    display: none;
  }
  .registration-form__title {
    font-size: 9.2rem;
    line-height: 9.2rem;
    margin-bottom: 6rem;
  }
  .registration-form__input-group {
    margin-bottom: 6rem;
  }
  .registration-form__input-group--half {
    width: 100%;
  }
  .registration-form__input-group--double {
    display: unset;
  }
  .registration-form__input-group--double .registration-form__input-group:not(:first-child) {
    margin-left: 0;
  }
  .registration-form__input {
    background-color: #f5f5f5;
    border: none;
    padding: 0 6.6rem 0 2.5rem;
  }
  .registration-form__input-error-message {
    bottom: -5rem;
  }
  .registration-form__select-input {
    font-size: 4rem;
  }
  .registration-form__input-box {
    height: 8rem;
  }
  .registration-form__label {
    font-size: 2.8rem;
    line-height: 3.6rem;
  }
  .registration-form__label::after {
    font-size: 5.5rem;
  }
  .registration-form__input-icon {
    width: 5.6rem;
    right: 2rem;
  }
  .registration-form__btn {
    border-radius: 2.8rem;
    width: 100%;
    height: 13rem;
    font-size: 4rem;
    line-height: 3rem;
    padding: 5rem;
  }
  .registration-form__redirect {
    font-size: 2.6rem;
    line-height: 2.6rem;
    margin-bottom: 5rem;
    margin-top: 5rem;
  }
  .registration-form--timed {
    padding-top: 5rem;
  }
  .registration-form__lock-paragraph {
    font-size: 3.4rem;
    line-height: 4.2rem;
  }
  .registration-form__lock-warning {
    font-size: 3.4rem;
    line-height: 4.2rem;
  }
  .registration-form__new-user-message {
    font-size: 3.4rem;
    line-height: 4.2rem;
    width: 100%;
  }
  .registration-header {
    padding: 4rem 0;
  }
  .registration-header__tickets {
    font-size: 4rem;
    line-height: 4rem;
  }
  .registration-header__timer {
    width: 24rem;
    height: 24rem;
  }
  .registration-header__title {
    font-size: 3.6rem;
    line-height: 3.6rem;
  }
  .PhoneInput {
    border: none;
    border-radius: 2px;
    background-color: #f5f5f5;
    width: 100%;
    padding-left: 2rem;
  }
  .PhoneInput--error {
    border: none;
  }
  .PhoneInputInput {
    height: 8rem;
    font-size: 4rem;
    background-color: #f5f5f5;
    border: none;
    padding: 0 6.6rem 0 2.5rem;
  }
  .PhoneInputCountrySelect {
    width: 10rem;
  }
  .PhoneInputCountryIcon {
    width: 3rem !important;
    height: 3rem !important;
  }
  .error-message {
    font-size: 2.6rem;
    line-height: 4.6rem;
    font-weight: 500;
  }
  .success-display {
    padding: 0;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  .success-display__tickets {
    font-size: 3.8rem;
    line-height: 3.8rem;
  }
  .success-display__event {
    font-size: 3.8rem;
    line-height: 3.8rem;
  }
  .success-display__icon-check {
    width: 24rem;
    height: 24rem;
    margin: 6rem 0;
  }
  .success-display__message {
    font-size: 4rem;
    line-height: 4rem;
  }
  .success-display__timer-wrapper .countdown__time, .success-display__timer-wrapper .countdown__separator, .success-display__timer-wrapper .countdown__expired {
    font-size: 5rem;
    line-height: 5rem;
  }
  .payment-iframe {
    height: 100%;
  }
  .expiry-popup__body {
    padding: 3rem;
  }
  .expiry-popup__action-message {
    font-weight: bold;
    font-size: 3.25rem;
    line-height: 3.77rem;
    text-transform: uppercase;
    margin-bottom: auto;
  }
  .expiry-popup__message {
    font-size: 3.25rem;
    line-height: 3.77rem;
    text-transform: uppercase;
    margin: 7rem 0;
  }
  .expiry-popup__btn {
    border-radius: 2rem;
    width: 100%;
    height: 9.8rem;
    font-size: 2.8rem;
    line-height: 2.8rem;
    margin-bottom: 4rem;
  }
  .expiry-popup__icon {
    display: block;
    height: 8.3rem;
    margin-top: auto;
  }
  .order-card__event-title, .order-card__summary-price {
    font-weight: 500;
  }
  .order-card__event-detail, .order-card__summary-date {
    font-size: 2.2rem;
    line-height: 2.2rem;
  }
  .order-card__order-summary {
    margin-right: 2rem;
  }
  .myorders {
    padding: 2rem;
  }
  .payment-confirmation {
    flex-direction: unset;
    display: block;
    overflow-y: auto;
    height: calc(100vh - 11rem);
    height: calc(var(--vh, 1vh) * 100 - 11rem);
  }
  .payment-confirmation--with-action-bar {
    height: calc(100vh - 31rem);
    height: calc(var(--vh, 1vh) * 100 - 31rem);
  }
  .payment-confirmation--with-action-bar .payment-confirmation__event,
  .payment-confirmation--with-action-bar .payment-confirmation__details {
    height: 50%;
  }
  .payment-confirmation__event-image-text {
    bottom: 4rem;
  }
  .payment-confirmation__event {
    flex: unset;
    height: 50%;
    margin: 0;
  }
  .payment-confirmation__details {
    margin-right: 0;
    overflow-y: unset;
    height: 50%;
    margin: 0;
  }
  .payment-confirmation__payment-method-status {
    font-size: 2.6rem;
    line-height: 2.5rem;
  }
  .payment-confirmation__payment-method-icon {
    width: 4rem;
  }
  .payment-confirmation__reference-title {
    font-size: 2.6rem;
    line-height: 2.5rem;
  }
  .payment-confirmation__reference-number {
    font-size: 3.5rem;
    line-height: 3.4rem;
  }
  .payment-confirmation__payment-method-image {
    width: 8rem;
  }
  .payment-confirmation__success-icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1.5rem;
  }
  .payment-confirmation__error-icon {
    width: 4rem;
    height: 4rem;
    margin-right: 1.5rem;
  }
  .payment-confirmation__paragraph {
    font-size: 3.4rem;
  }
  .payment-confirmation__download-icon {
    width: 3.5rem;
    height: 3.5rem;
    margin-right: 1rem;
  }
  .payment-confirmation__download-btn {
    font-size: 3rem;
    line-height: 3rem;
    font-weight: 600;
    padding: 2rem;
    border-radius: 2rem;
    margin-top: 3rem;
  }
  .payment-confirmation__title {
    font-size: 4rem;
    line-height: 4rem;
    flex-direction: column;
    align-items: flex-start;
  }
  .payment-confirmation__kiosk-dialog-status {
    font-size: 2.9rem;
    line-height: 2.9rem;
  }
  .payment-confirmation__kiosk-description {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
  .payment-confirmation__share-and-directions {
    height: 15rem;
  }
  .payment-confirmation .checkout__grand-total-title,
  .payment-confirmation .checkout__grand-total-value {
    font-weight: 400;
  }
  .payment-confirmation__fail-paragraph {
    font-size: 2.6rem;
  }
  .payment-confirmation__cancel-order {
    font-size: 3rem;
    line-height: 3rem;
  }
  .payment-confirmation__cancel-order-icon {
    width: 2rem;
    height: 2rem;
  }
  .payment-confirmation__btn-container {
    justify-content: center;
  }
  .payment-confirmation__btn {
    width: 80%;
    border-radius: 2rem;
    font-size: 3.4rem;
    line-height: 3.4rem;
    padding: 3rem;
  }
  .payment-confirmation__upgrade-dialog-title {
    font-size: 3.5rem;
    line-height: 3.4rem;
  }
  .payment-confirmation__upgrade-dialog-description {
    font-size: 2.8rem;
    line-height: 3.2rem;
  }
  .tooltip {
    left: calc(50% - 12rem);
  }
  .tooltip__icon {
    width: 4.4rem;
    height: 4.4rem;
  }
  .tooltip__text-box {
    bottom: 6rem;
    width: 28rem;
  }
  .tooltip__text-box::after {
    width: 2.4rem;
    height: 2.4rem;
    bottom: -1rem;
  }
  .tooltip__title {
    font-size: 2.6rem;
    line-height: 3.2rem;
  }
  .tooltip__message {
    font-size: 2.2rem;
    line-height: 2.6rem;
  }
  .tooltip__close-icon {
    font-size: 2rem;
  }
  .mobile-nav {
    display: flex;
    justify-content: space-between;
  }
  .event-card {
    padding: 5rem 0;
  }
  .event-card__image-container {
    width: 29.65rem;
    height: 29.65rem;
  }
  .event-card__cta {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    opacity: 0;
  }
  .event-card__btn {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    margin: 0;
    border-radius: 0;
    width: 100%;
    height: 100%;
    font-weight: bold;
    font-size: 4.15rem;
    line-height: 4.15rem;
    z-index: 2;
  }
  .event-card__see-more:active, .event-card__see-more:visited, .event-card__see-more:link, .event-card__see-more:hover {
    display: none;
  }
  .event-card__see-more--mobile, .event-card__see-more--mobile:active, .event-card__see-more--mobile:visited, .event-card__see-more--mobile:link, .event-card__see-more--mobile:hover {
    display: block;
    font: normal normal 700 1.9rem/1.9rem telegraf;
    z-index: 3;
  }
  .event-card__text {
    margin-right: 0;
  }
  .event-card__artist {
    font-size: 1.85rem;
    line-height: 1.85rem;
  }
  .event-card__event-name {
    font-size: 2.8rem;
    line-height: 2.8rem;
  }
  .event-card__description {
    font-size: 2.32rem;
    line-height: 3.45rem;
    font-weight: normal;
    max-height: 14rem;
  }
  .event-card__info {
    margin-top: auto;
    margin-bottom: 1.5rem;
    font-size: 2.32rem;
    line-height: 2.32rem;
  }
  .event-card__share-area {
    z-index: 3;
  }
  .event-card__share-icon {
    width: 3.9rem;
    height: 4.2rem;
  }
  .event-card .share-list {
    bottom: unset;
    top: 100%;
    transform: translateY(10%);
    z-index: 3;
  }
  .event-summary {
    position: relative;
    background-color: transparent;
    box-shadow: none;
  }
  .event-summary__body {
    flex: 1;
    padding-right: 12rem;
    justify-content: flex-start;
  }
  .event-summary__image-container {
    flex: 0 0 15rem;
    height: 15rem;
  }
  .event-summary__artist, .event-summary__event-name {
    font-family: telegraf;
    font-style: normal;
    font-weight: normal;
    font-size: 3.25rem;
    line-height: 3.25rem;
  }
  .event-summary__artist {
    margin-bottom: 1.2rem;
  }
  .event-summary__event-info {
    font-size: 2.6rem;
    line-height: 2.6rem;
    margin-top: auto;
  }
  .event-summary__action:not(:last-child) {
    margin-right: 1rem;
  }
  .event-summary__event-location {
    display: block;
    margin-top: 1rem;
  }
  .event-summary__action-area {
    position: absolute;
    bottom: 2rem;
    right: 2rem;
  }
  .event-summary__action, .event-summary__action:link, .event-summary__action:visited, .event-summary__action:hover, .event-summary__action:active {
    font-size: 2.6rem;
    line-height: 2.6rem;
  }
  .event-summary__action span, .event-summary__action:link span, .event-summary__action:visited span, .event-summary__action:hover span, .event-summary__action:active span {
    display: none;
  }
  .event-summary__action-icon {
    flex: 0 0 4.6rem;
    height: 4.6rem;
  }
  .event-info-popup {
    padding: 10rem 0 0 0;
    border-top-right-radius: 5rem;
    border-top-left-radius: 5rem;
    background-color: black;
    height: 95%;
  }
  .event-info-popup .popup__close {
    background-color: #38383a;
    padding: 1rem;
    box-sizing: content-box;
    border-radius: 50%;
    stroke: black;
    right: 2.5rem;
    top: 2.5rem;
  }
  .event-info-popup .popup__close path {
    fill: black;
  }
  .event-info-popup .event-page {
    height: 100%;
    padding-bottom: 2rem;
  }
  .event-info-popup .event-page__body {
    height: 100%;
  }
  .mobile-header {
    padding: 2rem 3rem;
    display: block;
    color: white;
  }
  .mobile-header__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 3rem;
  }
  .mobile-header__title-text {
    font-family: telegraf;
    font-style: normal;
    font-weight: 500;
    font-size: 6.95rem;
    line-height: 6.95rem;
  }
  .mobile-header__subtitle {
    font-family: telegraf;
    font-style: normal;
    font-weight: normal;
    font-size: 3.45rem;
    line-height: 3.45rem;
    color: black;
  }
  .mobile-header__cancel {
    width: 3.5rem;
    height: 3.5rem;
  }
  .mobile-header__cancel-icon {
    width: 100%;
    height: 100%;
  }
  .mobile-header__cancel-icon path {
    fill: white;
  }
  .help-page {
    height: calc(98vh - 11rem);
    height: calc((var(--vh, 1vh) * 98) - 11rem);
    flex-direction: column;
  }
  .help-page__title-container {
    flex: unset;
  }
  .help-page__title {
    position: relative;
    top: unset;
    left: unset;
    transform: none;
    background-image: linear-gradient(to right, white 0%, #c4c4c4 55%);
    font-size: 9.25rem;
    line-height: 9.25rem;
  }
  .help-page__content {
    height: unset;
  }
  .faq__icon {
    width: 4.9rem;
  }
  .faq__question {
    font-size: 3.7rem;
    line-height: 5.8rem;
  }
  .faq__answer {
    font-size: 3.7rem;
    line-height: 4.2rem;
  }
  .forgot-password-page {
    width: 100%;
  }
  .forgot-password-page__form {
    width: 100%;
    padding: 0 5rem;
  }
  .forgot-password-page__paragraph {
    font-size: 3rem;
  }
  .reset-password-page {
    margin-left: 3rem;
    margin-right: 3rem;
    padding: 5rem 0rem;
    width: calc(100% - 6rem);
    margin-top: 20rem;
  }
  .presales-page {
    flex-direction: column;
    padding: 0;
    justify-content: unset;
    height: calc(100vh - 11rem - 20rem);
    height: calc((var(--vh, 1vh) * 100) - 11rem - 20rem);
  }
  .presales-page__success, .presales-page__error {
    font-size: 5.6rem;
  }
  .presales-page__guests-area, .presales-page__ticket-selector {
    width: 100%;
    flex: 1;
    margin-left: 0;
    padding: 0 4rem;
  }
  .presales-page__ticket-selector {
    margin-top: 5rem;
  }
  .presales-page__ticket-selector .event-page__title {
    display: block;
    margin-bottom: 0;
    font-weight: 500;
    font-size: 6.95rem;
    line-height: 6.95rem;
  }
  .presales-page .checkout__subtitle {
    display: block;
  }
  .presales-page .guests {
    width: calc(100% - 14rem);
  }
  .presales-page .page-title {
    font-size: 6rem;
    line-height: 6rem;
  }
  .presales-page .guests__add-btn {
    margin-left: 0;
  }
  .presales-page__description {
    font-size: 2.4rem;
  }
  .profile {
    flex-direction: column;
    width: 100%;
    height: unset;
    margin-top: 8rem;
  }
  .profile__side-bar {
    margin-right: 0;
    width: 100%;
    flex: unset;
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0;
  }
  .profile__body {
    width: 100%;
    flex: unset;
    border-top-right-radius: 0;
    border-top-left-radius: 0;
    padding-top: 8rem;
  }
  .profile__user-name {
    font-size: 4rem;
    line-height: 4rem;
  }
  .profile__picture-container {
    width: 25rem;
    height: 25rem;
  }
  .profile__tab-item {
    padding: 2rem;
  }
  .profile__tab-item-label {
    font-size: 3.8rem;
    line-height: 3.8rem;
  }
  .profile__tab-item-icon-container {
    width: 4.6rem;
    height: 4.6rem;
  }
  .profile__form-btn {
    padding: 2.2rem 3.5rem;
    font-size: 3.8rem;
    line-height: 3.8rem;
    border-radius: 2.4rem;
    flex: 1;
    height: 11rem;
  }
  .profile__form-message {
    font-size: 2.8rem !important;
    line-height: 2.8rem !important;
    margin-right: 1rem;
  }
  .seat-details__seats-delete-icon {
    width: 2.5rem;
    height: 2.5rem;
  }
  .seat-details__seats-table td {
    font-size: 3rem;
    line-height: 3.5rem;
  }
  .seat-details__button {
    padding: 2rem 2.5rem;
    border: 1px solid #eb7341;
    border-radius: 12px;
    font-size: 3.4rem;
    line-height: 3.4rem;
  }
  .seat-details__button-count {
    width: 6rem;
    height: 6rem;
  }
  .payment-selector__header {
    font-size: 4rem;
    text-align: center;
    padding: 4rem 0;
    margin-bottom: 40rem;
  }
  .payment-selector__btn {
    border-radius: 2rem;
    width: 100%;
    height: 9.8rem;
    font-size: 2.8rem;
    line-height: 2.8rem;
    margin-bottom: 4rem;
  }
}
@media only screen and (max-width: 48em) {
  html {
    font-size: 6px;
  }
  .custom-checkbox {
    padding-left: 5rem;
  }
  .custom-checkbox .checkmark {
    width: 18px;
    height: 18px;
  }
  .custom-checkbox .checkmark:after {
    width: 5px;
    height: 10px;
  }
  .custom-radio {
    padding-left: 5rem;
  }
  .custom-radio .checkmark {
    width: 18px;
    height: 18px;
  }
  .custom-radio .checkmark:after {
    width: 12px;
    height: 12px;
  }
  .empty-page {
    padding: 10rem;
  }
  .empty-page__message {
    font-size: 4rem;
    line-height: 4.4rem;
  }
}
@media only screen and (max-width: 37.5em) {
  html {
    font-size: 4.8px;
  }
  .custom-checkbox {
    padding-left: 5rem;
  }
  .custom-checkbox .checkmark {
    width: 16px;
    height: 16px;
  }
  .custom-checkbox .checkmark:after {
    width: 4px;
    height: 8px;
    border-width: 0 1px 1px 0;
  }
  .custom-radio {
    padding-left: 5rem;
  }
  .custom-radio .checkmark {
    width: 16px;
    height: 16px;
  }
  .custom-radio .checkmark:after {
    width: 10px;
    height: 10px;
  }
}
@media only screen and (max-width: 25em) {
  html {
    font-size: 4.32px;
  }
  .cta-popup__btn .btn-line {
    border-right: 1px solid #fff;
  }
  .custom-checkbox {
    padding-left: 5rem;
  }
  .custom-checkbox .checkmark {
    width: 14px;
    height: 14px;
  }
  .custom-checkbox .checkmark:after {
    width: 4px;
    height: 8px;
  }
  .custom-radio {
    padding-left: 5rem;
  }
  .custom-radio .checkmark {
    width: 14px;
    height: 14px;
  }
  .custom-radio .checkmark:after {
    width: 8px;
    height: 8px;
  }
}
@media only screen and (max-width: 22em) {
  html {
    font-size: 3.52px;
  }
  .custom-checkbox .checkmark {
    width: 12px;
    height: 12px;
  }
  .custom-checkbox .checkmark:after {
    width: 3px;
    height: 7px;
  }
  .custom-radio .checkmark {
    width: 12px;
    height: 12px;
  }
}/*# sourceMappingURL=styles.css.map */