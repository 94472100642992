@import "colors";
@import "typography";
@import "base";
@import "loader";

.navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 3rem;
  height: $navbar-height;
  color: $color-text-primary;
  &__logo {
    object-fit: contain;
    width: 100%;
    height: 100%;
    &--placeholder {
      color: $color-text-primary;
      font-size: 2rem;
      text-transform: uppercase;
      font-family: $font-primary;
    }
  }
  &__logo-container {
    padding: 2rem 0;
    margin-right: auto;
    width: 19.1rem;
    text-decoration: none !important;
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__links {
    display: flex;
    align-items: center;
  }
  &__links-item {
    margin-right: 2rem;
  }
  &__link {
    &:link,
    &:visited,
    &:hover,
    &:active {
      font-family: $font-primary;
      font-style: normal;
      font-weight: 500;
      font-size: 1.8rem;
      line-height: 1.8rem;
      text-decoration: none;
      color: $color-text-primary;
      margin-right: 2rem;
    }
  }
  &__registration-btn {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 1.8rem;
    line-height: 1.8rem;
  }
  &__content {
    display: flex;
    align-items: center;
  }
  &__logout-btn {
    border: none;
    background-color: transparent;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 3rem;
    line-height: 3rem;
    text-decoration: underline;
    color: $color-primary;
    text-transform: uppercase;
  }
  &__welcome-message {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    text-transform: uppercase;
    margin-left: auto;
    margin-right: 3rem;
  }
  &__welcome-name {
    font-weight: bold;
  }
}
.popup {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 12;
  padding: 4rem;
  background-color: $color-bg;
  border-radius: 4px;
  width: 73rem;
  display: flex;
  justify-content: center;
  max-height: 100vh;
  max-height: calc(var(--vh, 1vh) * 100);
  &__close {
    width: 1.5rem;
    height: 1.5rem;
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    cursor: pointer;
    z-index: 50;
    path {
      fill: $color-text-primary;
    }
  }
  &__body {
    width: 100%;
  }
  &__error-message {
    padding: 2rem 0;
    font-weight: 500;
    font-size: 2rem !important;
  }
}
.overlay {
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  backdrop-filter: blur(5px);
  z-index: 11;
  background-color: rgba($color-overlay, 0.5);
  &--dark {
    background-color: rgba($color-overlay-dark, 0.9);
  }
}
.registration-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 2rem;
  &--login {
    width: 30rem;
    margin: auto;
  }
  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 2rem;
    line-height: 2rem;
    color: $color-primary;
    margin-bottom: 2rem;
    align-self: flex-start;
  }
  &__label {
    position: relative;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 2rem;
    margin-bottom: 3px;
    display: inline-block;
    color: $color-text-primary;
  }
  &__label--required::after {
    content: "*";
    color: red;
    position: absolute;
    font-size: 2.5rem;
  }
  &__input {
    height: 100%;
    width: 100%;
    padding: 0 2.4rem 0 1rem;
    border: 1px solid $color-text-tertiary;
    border-radius: 2px;
    &--error {
      border: 1px solid $danger;
    }
  }
  &__select-input {
    font-family: $font-primary;
    font-size: 1.6rem;
    &__value-container {
      padding: 0px 8px !important;
    }
    &__input-container {
      margin: 0px !important;
    }
  }
  &__input-group {
    position: relative;
    margin-bottom: 1.5rem;
    width: 100%;
    &--half {
      width: calc(50% - 0.5rem);
    }
    &--double {
      display: flex;
      justify-content: space-between;
      width: 100%;
      .registration-form__input {
        width: 100%;
      }
      .registration-form__input-group:not(:first-child) {
        margin-left: 1rem;
      }
      .registration-form__input-group {
        width: 100%;
      }
    }
  }
  &__input-error-message {
    position: absolute;
    bottom: -2rem;
  }
  &__btn {
    min-width: 15rem;
    margin-top: 3rem;
  }
  &__redirect {
    margin-top: 2rem;
    font-family: $font-primary;
    font-style: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    &--paragraph {
      font-weight: normal;
      color: $color-text-primary;
    }
    &--btn {
      font-weight: 500;
      color: $color-primary;
      cursor: pointer;
    }
  }
  &__forgot-password {
    color: $color-primary;
    margin-top: 0;
    align-self: flex-start;
    cursor: pointer;
    user-select: none;
  }
  &__input-icon {
    position: absolute;
    right: 1rem;
    top: 50%;
    transform: translateY(-50%);
    width: 1.5rem;
  }
  &__input-box {
    position: relative;
    height: 3rem;
  }
  &__form-error {
    align-self: flex-start;
  }
  &__lock-paragraph {
    margin-top: 4rem;
    text-align: center;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $color-text-primary;
  }
  &__lock-warning {
    text-align: center;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2.5rem;
    color: $color-primary;
    margin-bottom: 2rem;
  }
  &__new-user-message {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2.5rem;
    text-align: center;
    color: $color-text-primary;
    width: 40rem;
    margin-left: 50%;
    transform: translateX(-50%);
  }
  &__new-user-email {
    font-weight: bold;
  }
}
input:disabled {
  background-color: hsl(0, 0%, 70%);
  color: rgb(70, 70, 70);
}
.PhoneInput {
  border: 1px solid $color-text-tertiary;
  border-radius: 2px;
  height: 100%;
  &--error {
    border: 1px solid $danger;
  }
  background-color: white;
}
.PhoneInputCountry {
  padding-left: 1rem;
}
.PhoneInputInput {
  height: 2.5rem;
  width: 28rem;
  padding: 0 1rem;
  border: none;
}
.PhoneInputCountrySelect--focus {
  border: none;
}
.PhoneInputCountryIcon--border {
  background-color: transparent;
  box-shadow: unset !important;
}
.error-message {
  color: $danger;
  font-family: $font-primary;
  font-size: 1.4rem;
}
.btn {
  text-transform: uppercase;
  border-radius: 12px;
  border: none;
  padding: 1.5rem;
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  font-size: 1.8rem;
  line-height: 1.8rem;
  &--primary {
    background-color: $color-primary;
    color: $color-bg;
  }
  &--secondary {
    background-color: transparent;
    color: $color-primary;
    border: 1px solid $color-primary;
  }
  &--disabled {
    background-color: $color-grey-6;
    color: $color-text-primary;
  }
}
.featured {
  width: 100%;
  position: relative;
  background: linear-gradient(
      to top,
      $color-bg-overlay 15%,
      rgba(0, 0, 0, 0.03) 25%
    ),
    linear-gradient(
      to bottom,
      $color-bg-overlay -39.64%,
      rgba(0, 0, 0, 0.03) 20.75%
    );
  height: calc(100vh - #{$navbar-height});
  height: calc((var(--vh, 1vh) * 100) - #{$navbar-height});
  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    z-index: -1;
    position: relative;
  }
  &__body {
    position: absolute;
    left: 7.5rem;
    bottom: 20%;
    max-height: 65%;
    max-width: calc(100% - 35rem);
    overflow: hidden;
    text-align: left;
  }
  &__heading {
    display: inline-block;
    &:link,
    &:active,
    &:hover,
    &:visited {
      text-decoration: none;
    }
  }
  &__time {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 3rem;
    line-height: 3rem;
    color: $color-text-primary;
    text-transform: capitalize;
  }
  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 8rem;
    line-height: 7.7rem;
    color: $color-primary;
    margin: 3rem 0;
  }
  &__artist-title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: $color-text-primary;
    margin-bottom: 1.5rem;
    width: 40%;
  }
  &__artist-name {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: $color-text-primary;
    margin-bottom: 3rem;
    width: 40%;
  }
  &__location {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 2.5rem;
    color: $color-text-primary;
    margin-bottom: 3rem;
  }
  &__description {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 2.8rem;
    color: $color-text-primary;
    width: 40%;
    /* display: -webkit-box;
    line-clamp: 8;
    -webkit-line-clamp: 8;
    -webkit-box-orient: vertical;
    overflow: hidden; */
  }
  &__action {
    position: absolute;
    bottom: 20%;
    right: 7.5rem;
    background: transparent;
    border: 3px solid $color-primary;
    text-transform: uppercase;
    border-radius: 50%;
    width: 18rem;
    height: 18rem;
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.3rem;
    color: $color-bg;
    background-color: $color-primary;
    &--disabled {
      background-color: $color-grey-6;
      border: 3px solid $color-grey-6;
      color: $color-text-secondary;
      cursor: default;
    }
  }
  &__action:hover {
    color: $color-primary;
    background-color: $color-bg;
    border: 3px solid $color-bg;
  }
  &__action--disabled:hover {
    background-color: $color-grey-6;
    border: 3px solid $color-grey-6;
    color: $color-text-secondary;
    cursor: default;
  }
  &__link-to-events {
    &:link,
    &:visited,
    &:hover,
    &:active {
      position: absolute;
      display: flex;
      flex-direction: column;
      align-items: center;
      bottom: 4%;
      left: 50%;
      transform: translateX(-50%);
      text-transform: uppercase;
      text-decoration: none;
      color: $color-text-primary;
      font-family: $font-primary;
      font-style: normal;
      font-weight: bold;
      font-size: 1.8rem;
      line-height: 2rem;
    }
  }
  &__scroll-icon {
    width: 3.5rem;
    height: 4.9rem;
    margin-bottom: 1rem;
    display: block;
  }
  &__more-info,
  &__more-info:link,
  &__more-info:active,
  &__more-info:hover {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 2.2rem;
    line-height: 2.8rem;
    color: $color-primary;
    padding-left: 1rem;
  }
}
.stepper {
  display: flex;
  width: 70%;
  margin: auto;
  height: $stepper-height;
  align-items: center;
  position: relative;
  &__step {
    display: flex;
    align-items: center;
    color: $color-text-primary;
    font-family: $font-secondary;
    &--active {
      color: $color-primary;
    }
    &--completed {
      color: $color-primary;
      .stepper__step-bar {
        background-color: $color-primary;
      }
    }

    &--with-bar {
      flex: 1;
    }
  }
  &__step-label {
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.9rem;
    white-space: nowrap;
    padding: 0 1.2rem;
  }
  &__step-bar {
    width: 100%;
    height: 0.4rem;
    background-color: $color-text-primary;
  }
}
.event-page {
  display: flex;
  flex-direction: column;
  height: calc(100vh - #{$navbar-height} - #{$ctapopup-height});
  height: calc(
    (var(--vh, 1vh) * 100) - #{$navbar-height} - #{$ctapopup-height}
  );
  &__body {
    display: flex;
    flex: 1;
    padding: 0 4rem;
  }
  &--event-details {
    position: relative;
    display: block;
    height: 100%;
  }
  &__info {
    width: 60%;
    align-items: flex-start;
    /* border-right: 1px solid $color-grey-1; */
    /* padding: 4rem 4rem 0 4rem; */
    display: flex;
    position: relative;
    justify-content: center;
    height: calc(
      100vh - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    height: calc(
      (var(--vh, 1vh) * 100) - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    &--details-page {
      height: 100%;
      width: 100%;
      padding: 0;
      border: none;
    }
  }
  &__guests {
    width: 60%;
    height: calc(
      100vh - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    height: calc(
      (var(--vh, 1vh) * 100) - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    overflow-y: auto;
    padding-bottom: 2rem;
  }
  &__event-image-area {
    position: relative;
    width: 100%;
    height: 100%;
    background: linear-gradient(
      180.29deg,
      rgba($color-bg-overlay, 0) 30.82%,
      rgba($color-bg-overlay, 0.7) 64.93%
    );
  }
  &__event-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: relative;
    z-index: -1;
  }
  &__event-image-text {
    position: absolute;
    color: $color-text-primary;
    z-index: 3;
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    bottom: 15rem;
    left: 10rem;
    width: 40%;
  }
  &__event-image-date,
  &__event-image-location {
    margin-bottom: 2rem;
    font-size: 3rem;
    line-height: 3.5rem;
  }
  &__event-image-title {
    font-size: 5rem;
    line-height: 5rem;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    margin-bottom: 4rem;
    color: $color-primary;
  }
  &__description {
    width: 40%;
    padding: 1rem 2rem 1rem 2rem;
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    height: calc(
      98vh - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    height: calc(
      (var(--vh, 1vh) * 98) - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    overflow-y: auto;
    &--details-page {
      border-radius: 4px;
      height: 80%;
      height: unset;
      background-color: rgba($color-bg-overlay, 0.8);
      backdrop-filter: blur(20px);
      margin-right: 0 !important;
      padding: 5rem;
      width: 47%;
      position: absolute;
      top: 3rem;
      right: 6%;
      bottom: 15rem;
      color: $color-text-primary;
    }
  }
  &__description--padded,
  &__info--padded {
    padding-bottom: 2rem;
  }
  &__title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 2.5rem;
    color: $color-primary;
    &--ticket-selector {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
  &__paragraph {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 2.8rem;
    margin-bottom: 4rem;
  }
  &__event-day-details {
    display: flex;
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
  &__event-day-detail-col {
    flex: 1;
  }
  &__event-day-detail-title {
    margin-bottom: 3rem;
  }
  &__btn {
    width: 20rem;
    margin-top: 4rem;
    color: $color-text-secondary !important;
  }
  &__layout-image-area {
    position: relative;
    height: 100%;
    width: 100%;
  }
  &__layout-image {
    object-fit: contain;
    height: 100%;
    width: 100%;
  }
  &__ticket-subheader {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
  }
  &__ticket-max-warning {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-text-primary;
    flex: 1;
  }
  &__ticket-clear {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.4rem;
    text-transform: uppercase;
    color: $color-primary;
    cursor: pointer;
    height: 1.7rem;
    margin-top: 1rem;
  }
  &__ticket-clear-icon {
    width: 2.6rem;
    height: 1.7rem;
    margin-right: 1rem;
    path {
      fill: $color-primary;
    }
  }
  &__ticket-area {
    color: $color-text-primary;
    &--selected {
      border-bottom: 1px solid $color-text-primary;
      font-weight: bold;
    }
    &--not-selected {
      border-bottom: 1px solid $color-grey-1;
    }
  }
  &__ticket-row {
    user-select: none;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 2rem 0;
    cursor: pointer;
  }
  &__ticket-row > * {
    flex: 1;
  }
  &__ticket-name {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
  }
  &__ticket-color {
    display: inline-block;
    margin-right: 1rem;
    width: 2rem;
    height: 2rem;
    border-radius: 50%;
  }
  &__ticket-dropdown-icon {
    width: 2.2rem;
    height: 1rem;
    cursor: pointer;
    transition: transform 0.2s;
    &--expanded {
      transform: rotateX(180deg);
    }
    path {
      fill: $color-text-primary;
    }
  }
  &__ticket-dropdown-icon-wrapper {
    display: flex;
    flex-basis: 2.2rem;
    flex-grow: 0;
    justify-content: flex-end;
    height: 100%;
    align-items: center;
  }
  &__ticket-state {
    font-family: $font-primary;
    font-weight: bold;
    font-size: 2rem;
  }
  &__ticket-name-wrapper {
    &--expired {
      text-decoration: line-through;
      color: $color-grey-3;
    }
  }
  &__ticket-counter {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__ticket-price {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
  &__ticket-count {
    width: 5rem;
    text-align: center;
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3rem;
  }
  &__ticket-counter-icon {
    border: none;
    background-color: transparent;
    display: flex;
    height: 2rem;
    width: 2rem;
    align-items: center;
    path {
      fill: $color-text-primary;
    }
  }
  &__ticket-description {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.8rem;
    transition: padding-top 0.2s;
    height: 0;
    overflow: hidden;
    &--expanded {
      padding: 5rem 3rem;
      height: unset;
    }
  }
  &__event-image-btns {
    height: 14rem;
    display: flex;
    justify-content: space-between;
    &--desktop {
      flex-direction: column;
      margin-top: 3rem;
    }
    &--mobile {
      display: none;
    }
  }
}
.labeled-btn-container {
  display: flex;
}
.labeled-btn {
  text-decoration: none;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  &__icon {
    width: 5.5rem;
    height: 5.5rem;
    &--share {
      circle {
        stroke: $color-primary;
      }
      path {
        fill: $color-primary;
      }
    }
    &--directions {
      path:nth-child(1),
      path:nth-child(3),
      path:nth-child(4) {
        stroke: $color-primary;
      }
      path:nth-child(2),
      path:nth-child(4) {
        fill: $color-primary;
      }
    }
  }
  &__text {
    padding-left: 2rem;
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    color: $color-primary;
  }
}
.share-list {
  position: absolute;
  top: 100%;
  right: 0;
  transform: translateY(10%);
  background-color: white;
  padding: 1rem 2rem;
  border-radius: 4px;
  box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.36);
  &__close-icon {
    position: absolute;
    top: 0.75rem;
    right: 0.75rem;
    width: 1.25rem;
    height: 1.25rem;
    cursor: pointer;
  }
  &__btn {
    margin-right: 1rem;
    margin-bottom: 1rem;
    margin-top: 1rem;
  }
  &__btn:last-child {
    margin-right: 0;
  }
}
.cta-popup {
  height: $ctapopup-height;
  width: 100%;
  background-color: $color-bg;
  color: $color-text-primary;
  box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 5rem;
  position: fixed;
  bottom: 0;
  z-index: 7;
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3rem;
    color: $color-text-primary;
  }
  &__text-area {
    flex: 1;
  }
  &__warning {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 2.8rem;
    text-transform: uppercase;
    color: $danger;
    margin-bottom: 1rem;
  }
  &__display-message {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2rem;
    color: $color-text-primary;
  }
  &__timer {
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    .countdown {
      color: $color-tertiary;
    }
  }
  &__btn-box {
    flex: 1;
    display: flex;
    justify-content: flex-end;
  }
  &__error-btn {
    display: flex;
    justify-content: center;
    &--cancel {
      margin-right: 2rem;
    }
  }
  &__btn {
    display: flex;
    align-items: center;
    width: 35.8rem;
    height: 6rem;
    &--disabled {
      background-color: $color-grey-1;
    }
    .btn-line {
      width: 1px;
      height: 2.5rem;
      border-right: 2px solid $color-text-secondary;
    }
    &--1,
    &--2 {
      flex: 1;
    }
  }
  &__btn--width-icon {
    width: 35.8rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__arrow-icon {
    width: 3.4rem;
    height: 2.4rem;
    margin-left: 3rem;
    path {
      fill: $color-text-secondary;
    }
  }
}
.event-image-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background: rgba($color-primary, 0.2);
  backdrop-filter: blur(4px);
  z-index: 2;
}
.countdown {
  display: flex;
  color: $color-primary;
  &__time,
  &__separator,
  &__expired {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3rem;
    &--danger {
      color: $danger;
    }
  }
  &__label {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
  }
}
.registration-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 2rem 2rem 4rem 2rem;
  border-bottom: 1px solid $color-grey-2;
  &__info {
    color: $color-text-primary;
  }
  &__timer {
    display: flex;
    width: 14rem;
    height: 14rem;
    justify-content: center;
    align-items: center;
    svg {
      path:nth-child(2) {
        stroke: $color-primary;
      }
    }
  }
  &__tickets {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $color-text-primary;
    border-radius: 100px;
    padding: 2rem;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 3rem;
    line-height: 3rem;
    color: $color-text-primary;
  }
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2rem;
    margin-top: 2rem;
  }
  &__icon {
    margin-right: 2rem;
    path {
      fill: $color-text-primary;
    }
  }
}
.checkout {
  &__payment,
  &__details {
    overflow-y: auto;
  }
  &__payment {
    width: 60%;
    height: calc(
      100vh - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    height: calc(
      (var(--vh, 1vh) * 100) - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    padding: 4rem 12rem 2rem 4rem;
  }
  &__details {
    padding: 1rem 2rem;
    box-shadow: 0px 4px 10px 10px rgba(0, 0, 0, 0.03);
    border-radius: 6px;
    width: 40%;
    height: calc(
      98vh - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    height: calc(
      (var(--vh, 1vh) * 98) - #{$navbar-height} - #{$stepper-height} - #{$ctapopup-height}
    );
    box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2);
    .receipt-table {
      tbody tr:last-child td {
        border: none;
      }
    }
  }
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }
  &__subtitle {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    color: $color-text-primary;
    position: relative;
    display: inline-block;
    &--required {
      &::after {
        content: "*";
        color: red;
        position: absolute;
        font-size: 2.5rem;
      }
    }
  }
  &__paragraph {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
    color: $color-text-primary;
  }
  &__subparagraph {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: $color-grey-3;
    margin-bottom: 1rem;
  }
  &__receipt {
    .checkout__grand-total {
      display: none;
    }
  }
  &__receipt-toggle {
    user-select: none;
    cursor: pointer;
    display: flex;
    justify-content: space-between;
    align-items: center;
    border-radius: 100px;
    padding: 2rem;
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 2.5rem;
    line-height: 2.5rem;
    border: 1px solid $color-text-primary;
    color: $color-text-primary;
    &--opened {
      border: 1px solid $color-primary;
      color: $color-primary;
    }
  }
  &__receipt-icon {
    width: 2rem;
    height: 1rem;
    margin-left: 2rem;
  }
  &__grand-total {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__grand-total-title,
  &__grand-total-value {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3rem;
    color: $color-text-primary;
    text-transform: uppercase;
  }
  &__grand-total-value {
    text-align: right;
  }
  &__grand-total-subparagraph {
    color: $color-grey-3;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    margin-top: 1rem;
    text-transform: none;
    text-align: right;
  }
  &__mobile-collapse-bar {
    display: none;
    background-color: $color-grey-7;
    border-radius: 100px;
    margin: auto;
  }
  &__order-actions {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    user-select: none;
    align-items: center;
  }
  &__edit-addons {
    display: flex;
    align-items: center;
    color: $color-primary;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-align: right;
    cursor: pointer;
    user-select: none;
    margin-right: 2rem;
  }
  &__edit-guests {
    display: flex;
    align-items: center;
    color: $color-primary;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-align: right;
    cursor: pointer;
    user-select: none;
    margin-right: 2rem;
  }
  &__edit-addons-icon,
  &__cancel-order-icon,
  &__edit-guests-icon {
    width: 2.4rem;
    height: 2.4rem;
    margin-right: 0.5rem;
    path {
      fill: $color-primary;
    }
  }
  &__receipt {
    margin-top: 5rem;
  }
  &__cancel-order {
    display: inline-block;
    user-select: none;
    color: $color-primary;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    cursor: pointer;
    display: flex;
    align-items: center;
  }
  &__cancel-order-icon {
    width: 1.4rem;
    height: 1.4rem;
    path {
      fill: $color-primary;
    }
  }
  &__toggle {
    display: none;
  }
}
.addons-list {
  padding-bottom: 2rem;
}
.addon {
  width: 100%;
  padding: 3rem 0;
  border-bottom: 1px solid $color-grey-1;
  &--selected {
    border-bottom: 1px solid $color-text-primary;
  }
  &--selected &__name,
  &--selected &__price {
    font-weight: bold;
  }
  &__body {
    display: flex;
    align-items: center;
    user-select: none;
  }
  &__base {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    cursor: pointer;
  }
  &__info {
    flex: 1;
  }
  &__name {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: $color-text-primary;
    cursor: pointer;
  }
  &__price {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $color-text-primary;
    margin-top: 1rem;
  }
  &__counter {
    display: flex;
    align-items: center;
    flex: 1;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2rem;
    color: $color-text-primary;
  }
  &__counter-icon-wrapper {
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    margin-right: 1rem;
    margin-left: 1rem;
    cursor: pointer;
    path {
      fill: $color-text-primary;
    }
  }
  &__counter-icon {
    width: 100%;
  }
  &__dropdown-icon {
    cursor: pointer;
    &--opened {
      transform: rotate(180deg);
    }
    path {
      fill: $color-text-primary;
    }
  }
  &__description {
    margin-top: 1rem;
    margin-left: 3.5rem;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.8rem;
    color: $color-text-primary;
  }
}
.payment-method {
  padding: 3rem 0;
  border-bottom: 1px solid $color-grey-1;
  cursor: pointer;
  &--selected {
    border-bottom: 1px solid $color-text-primary;
  }
  &__body {
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__name {
    flex: 1;
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-text-primary;
    text-transform: uppercase;
    /* .tooltip {
      position: relative;
      left: unset;
      margin-left: 1rem;
      text-transform: none;
      &__icon {
        width: 1.6rem;
        height: 1.6rem;
      }
      &__text-box {
        bottom: 3rem;
      }
    } */
  }
  &__description {
    font-size: 1.2rem;
    line-height: 1.5rem;
    font-weight: 400;
    display: inline-block;
  }
  &__icon {
    width: 2.4rem;
    margin-right: 1rem;
    path {
      stroke: $color-text-primary;
    }
    &--filled {
      path {
        fill: $color-text-primary;
      }
    }
    &--image {
      width: 4rem;
    }
  }
}
.receipt-table {
  width: 100%;
  border-collapse: collapse;
  th:first-child {
    text-align: left;
  }
  th:last-child {
    text-align: right;
  }
  th {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    color: $color-text-primary;
  }
  td {
    text-align: center;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $color-text-primary;
    padding: 1rem 0;
    border-bottom: 1px solid $color-text-primary;
    width: 33%;
  }
  tr td:first-child {
    text-align: left;
  }
  tr td:last-child {
    text-align: right;
  }
  tbody tr:first-child td {
    padding-top: 3rem;
  }
  /* tbody tr:last-child td {
    border: none;
  } */
  &__subtotal-tr td {
    border: none;
    padding: 3rem 0;
  }
  &__subtotal-tr &__subtotal-text {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $color-text-primary;
  }
}
*{
  /* width */
  &::-webkit-scrollbar {
    width: 3px;
    margin-right: 10px;
  }

  /* Track */
  &::-webkit-scrollbar-track {
    background: transparent;
    border-radius: 100px;
  }

  /* Handle */
  &::-webkit-scrollbar-thumb {
    background: $color-grey-4;
    border-radius: 100px;
  }

  /* Handle on hover */
  &::-webkit-scrollbar-thumb:hover {
    background: $color-grey-3;
  }
}
.event-page__description,
.checkout__details,
.payment-confirmation__details {
  margin-right: 3px;
}
.highest-z-index {
  z-index: 100;
}
.success-display {
  height: 80vh;
  padding: 10rem 0;
  text-align: center;
  &__tickets {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2rem;
    color: $color-text-primary;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 2rem;
  }
  &__icon-lock {
    width: 2rem;
    margin-right: 0.75rem;
  }
  &__event {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2rem;
    color: $color-text-primary;
    text-align: center;
  }
  &__icon-check {
    width: 16rem;
    margin-bottom: 2rem;
    margin-top: 2rem;
  }
  &__message {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 3rem;
    color: $color-primary;
    text-transform: uppercase;
  }
  &__timer-wrapper {
    display: flex;
    justify-content: center;
  }
}
.payment-iframe {
  width: 100%;
  max-width: 767px;
  height: 95vh;
  height: calc(var(--vh, 1vh) * 95);
  &--loading {
    opacity: 0;
  }
  margin-left: 50%;
  transform: translateX(-50%);
}
.payment-confirmation {
  display: flex;
  padding: 0 8rem;
  height: calc(100vh - #{$navbar-height});
  height: calc((var(--vh, 1vh) * 100) - #{$navbar-height});
  align-items: center;
  &__btn-container {
    display: flex;
    justify-content: flex-end;
    margin-top: 5rem;
  }
  &__btn {
    width: 30rem;
  }
  &--with-action-bar {
    height: calc(100vh - #{$navbar-height} - #{$ctapopup-height});
    height: calc(
      (var(--vh, 1vh) * 100) - #{$navbar-height} - #{$ctapopup-height}
    );
    .payment-confirmation__event,
    .payment-confirmation__details {
      height: calc(95vh - #{$navbar-height} - #{$ctapopup-height});
      height: calc(
        (var(--vh, 1vh) * 95) - #{$navbar-height} - #{$ctapopup-height}
      );
    }
  }
  &__event,
  &__details {
    flex: 1;
    height: calc(90vh - #{$navbar-height});
    height: calc((var(--vh, 1vh) * 90) - #{$navbar-height});
  }
  &__event {
    background: linear-gradient(
      180.29deg,
      rgba(0, 0, 0, 0) 30.82%,
      rgba(0, 0, 0, 0.7) 64.93%
    );
    position: relative;
    margin-right: 4rem;
    border-radius: 12px;
    overflow: hidden;
  }
  &__event-image-text {
    position: absolute;
    color: $color-text-primary;
    z-index: 3;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: bold;
    bottom: 10rem;
    left: 4rem;
  }
  &__event-image {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: absolute;
    z-index: -1;
  }
  &__success-icon {
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 1.5rem;
  }
  &__error-icon {
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 1.5rem;
    path {
      fill: $danger;
    }
  }
  &__pending-icon {
    width: 2.8rem;
    height: 2.8rem;
    margin-right: 1.5rem;
    path {
      fill: $warning;
    }
  }
  &__title-container {
    display: flex;
  }
  &__download-icon {
    width: 2.4rem;
    height: 2.8rem;
    margin-right: 1rem;
    path {
      fill: $color-primary;
    }
  }
  &__event-image-date {
    margin-bottom: 2rem;
    font-size: 3.2rem;
    line-height: 3.5rem;
    color: $color-primary;
  }
  &__event-image-title {
    font-style: normal;
    font-weight: 500;
    font-size: 5rem;
    line-height: 5rem;
    color: $color-text-primary;
    text-transform: uppercase;
  }
  &__details {
    padding: 5rem 0;
    margin-left: 4rem;
    overflow-y: auto;
  }
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 3rem;
    line-height: 3rem;
    color: $color-text-primary;
    text-transform: uppercase;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 5rem;
  }
  &__paragraph {
    font-family: $font-primary;
    color: $color-text-primary;
    font-size: 2.3rem;
  }
  &__status {
    font-family: $font-primary;
  }
  &__status-success {
    color: $success;
  }
  &__status-error {
    color: $danger;
  }
  &__status-pending {
    color: $warning;
  }
  .checkout__receipt {
    padding-bottom: 2rem;
    margin-top: 3rem;
  }
  .checkout__grand-total {
    margin-bottom: 2rem;
  }
  &__payment-method {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid $color-grey-1;
    padding-top: 2rem;
  }
  &__payment-method-status {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    color: $color-text-primary;
    display: flex;
    flex-direction: column;
    &--success {
      color: $success;
    }
    &--error {
      color: $danger;
    }
  }
  &__payment-method-icon {
    width: 1.9rem;
    margin-right: 1rem;
  }
  &__payment-method-image {
    margin-top: 2rem;
    width: 6.4rem;
  }
  &__payment-method-details {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
  }
  &__reference-title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    color: $color-grey-3;
  }
  &__reference-number {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: $color-grey-3;
    margin-bottom: 1rem;
  }
  &__download-btn {
    text-transform: uppercase;
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.8rem;
    text-decoration: none;
    display: flex;
    align-items: center;
  }
  &__kiosk-dialog {
    background-color: $color-grey-8;
    padding: 3rem;
    border-radius: 6px;
  }
  &__kiosk-dialog-status {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    margin-top: 2rem;
  }
  &__kiosk-description {
    font-family: $font-primary;
    font-size: 1.6rem;
    line-height: 2rem;
  }
  &__share-and-directions {
    display: flex;
    height: 13rem;
    justify-content: space-between;
    flex-direction: column;
    margin-bottom: 2rem;
  }
  &__fail-paragraph {
    font-family: $font-primary;
    font-size: 1.6rem;
    font-weight: 500;
    color: $color-secondary;
  }
  &__fail-link,
  &__fail-link:active,
  &__fail-link:visited,
  &__fail-link:hover,
  &__fail-link:link {
    color: $color-primary;
  }
  &__cancel-order-container {
    display: flex;
    justify-content: flex-end;
  }
  &__cancel-order {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 1.8rem;
    cursor: pointer;
    display: flex;
    align-items: center;
    color: $danger;
    user-select: none;
  }
  &__cancel-order-icon {
    width: 1.4rem;
    height: 1.4rem;
    margin-right: 0.5rem;
    path {
      fill: $danger;
    }
  }
  &__upgrade-dialog {
    background-color: $color-bg-secondary;
    padding: 3rem;
    border-radius: 6px;
  }
  &__upgrade-dialog-title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2.5rem;
    line-height: 2.5rem;
    color: $color-text-primary;
    margin-bottom: 1rem;
  }
  &__upgrade-dialog-description {
    font-family: $font-primary;
    font-size: 1.6rem;
    line-height: 2rem;
    color: $color-text-primary;
    &--bold {
      font-weight: bold;
    }
  }
}

.payment-redirect {
  position: relative;
  width: 100%;
  height: calc(100vh - #{$navbar-height});
  height: calc((var(--vh, 1vh) * 100) - #{$navbar-height});
  &__loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}
.page-title {
  font-family: $font-secondary;
  font-style: normal;
  font-weight: 500;
  font-size: 5rem;
  line-height: 5rem;
  color: $color-primary;
}
.myorders {
  padding: 4rem;
}
.order-card {
  padding: 2rem 0;
  border-top: 1px solid $color-text-primary;
  border-bottom: 1px solid $color-text-primary;
  margin: 3rem 0;
  display: flex;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  &__image-container {
    width: 19rem;
    height: 11rem;
    margin-right: 2rem;
  }
  &__image {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  &__event-title {
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    color: $color-primary;
  }
  &__event-title,
  &__summary-price {
    font-size: 3rem;
    line-height: 3rem;
    margin-bottom: 2rem;
  }
  &__summary-price {
    text-align: right;
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    color: $color-text-primary;
  }
  &__event-detail,
  &__summary-date {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: $color-text-primary;
  }
  &__event-detail:not(:first-of-type) {
    margin-left: 2rem;
  }
  &__order-summary {
    margin-left: auto;
    margin-right: 4rem;
  }
  &__summary-date {
    text-align: right;
  }
  &__redirect-icon {
    width: 4rem;
    path {
      fill: $color-text-primary;
    }
  }
  &__order-status {
    font-family: $font-primary;
    font-style: normal;
    font-size: 1.7rem;
    font-weight: 400;
    text-transform: uppercase;
    &--success {
      color: $success;
    }
    &--error {
      color: $danger;
    }
    &--pending {
      color: $warning;
    }
  }
}
.sandwich {
  &__checkbox {
    display: none;
  }
  &__button {
    cursor: pointer;
    position: relative;
    z-index: 10;
    height: 3rem;
    width: 3rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  &__button:hover > &__icon--1 {
    margin-bottom: 1.2rem;
  }
  &__icon {
    width: 3rem;
    height: 2px;
    background-color: $color-text-primary;
    transition: all 0.2s;
    &--1 {
      margin-bottom: 1rem;
    }
  }
  &__background {
    position: fixed;
    top: 2rem;
    right: 1.5rem;
    width: 6rem;
    height: 6rem;
    border-radius: 50%;
    background-color: transparent;
    z-index: 8;
    transition: all 0.6s;
  }
  &__nav {
    position: fixed;
    width: 0;
    height: 100vh;
    height: calc(var(--vh, 1vh) * 100);
    top: 0;
    left: 0;
    z-index: 9;
    opacity: 0;
    visibility: hidden;
    transition: all 0.5s ease;
    user-select: none;
    overflow-y: auto;
  }
  &__nav-body {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    text-align: center;
    max-height: 100vh;
    max-height: calc(var(--vh, 1vh) * 100);
  }
  &__nav-list {
    list-style: none;
    margin-top: auto;
  }
  &__nav-item {
    margin-bottom: 4rem;
    text-align: center;
  }
  &__nav-link {
    &:visited,
    &:link,
    &:active,
    &:hover {
      display: block;
      text-decoration: none;
      font-family: $font-primary;
      font-style: normal;
      font-weight: normal;
      font-size: 10rem;
      line-height: 10.5rem;
      color: $color-primary;
      transition: transform 0.3s ease-in-out;
      white-space: nowrap;
      @supports (
        (background-clip: text) and
          (
            background-image:
              linear-gradient(to right, $color-primary, $color-grey-2)
          )
      ) {
        background-image: linear-gradient(
          to right,
          $color-primary,
          $color-grey-2
        );
        background-clip: text;
        color: transparent;
      }
    }
  }
  &__nav-socials {
    margin-top: 5rem;
    text-align: center;
    margin-bottom: auto;
  }
  &__nav-socials-paragraph {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 3rem;
    line-height: 3rem;
    color: $color-primary;
    margin-bottom: 1.5rem;
  }
  &__nav-socials-icon {
    width: 4rem;
    path {
      fill: $color-primary;
    }
  }
  &__nav-socials-link {
    display: inline-block;
    &:not(:first-of-type) {
      margin-left: 2rem;
    }
  }
  &__nav-item:hover > &__nav-link {
    transform: rotateY(180deg);
  }
  &__checkbox:checked ~ &__button &__icon--1 {
    transform: rotate(135deg);
    margin-bottom: -2px;
  }
  &__checkbox:checked ~ &__button {
    position: fixed;
    top: 4rem;
    right: 3rem;
  }
  &__checkbox:checked ~ &__button &__icon--2 {
    transform: rotate(-135deg);
  }
  &__checkbox:checked ~ &__background {
    transform: scale(80);
    background-color: $color-bg;
  }
  &__checkbox:checked ~ &__nav {
    opacity: 1;
    width: 100%;
    visibility: visible;
  }
  &__terms {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 2.5rem;
    color: $color-secondary;
    margin-top: auto;
    margin-bottom: 2rem;
    &--link {
      &:active,
      &:link,
      &:visited,
      &:hover {
        color: $color-primary;
      }
    }
  }
}
.page-loader {
  position: fixed;
  z-index: 12;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
  &__body {
    position: absolute;
    max-width: 60rem;
    text-align: center;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 4rem;
    line-height: 4rem;
    color: $color-text-primary;
    margin-bottom: 2rem;
  }
  &__message {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 4rem;
    line-height: 4rem;
    color: $color-text-primary;
  }
}
.expiry-popup {
  &__header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: center;
    border-bottom: 1px solid $color-grey-2;
  }
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 2rem;
    line-height: 2rem;
    text-transform: uppercase;
    color: $color-text-primary;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }
  &__message,
  &__action-message {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 2rem;
    line-height: 2.5rem;
    margin-top: 3rem;
    margin-bottom: 3rem;
    text-align: center;
    color: $color-text-primary;
    &--error {
      color: $danger;
      font-weight: 500;
    }
  }
  &__action-message {
    color: $color-text-primary;
    margin-top: 0;
    margin-bottom: 3rem;
  }
  &__btn {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.4rem;
    height: 5.7rem;
    width: 38rem;
    margin-bottom: 1.5rem;
  }
  &__icon {
    display: none;
    path {
      fill: $danger;
    }
  }
}
.tooltip {
  position: absolute;
  left: calc(50% - 8.3rem);
  z-index: 12;
  path {
    fill: $color-tertiary;
    stroke: $color-bg;
  }
  &__icon {
    width: 2.2rem;
    height: 2.2rem;
  }
  &__text-box {
    display: block;
    padding: 1rem;
    background-color: $color-tertiary;
    color: $color-bg;
    border-radius: 4px;
    width: 20rem;
    position: absolute;
    bottom: 3.3rem;
    left: -1rem;
  }
  &__text-box::after {
    content: "";
    display: block;
    position: absolute;
    bottom: -0.5rem;
    left: 2rem;
    width: 1rem;
    height: 1rem;
    background-color: $color-tertiary;
    transform: rotate(45deg);
    z-index: -1;
  }
  &__icon:hover ~ &__text-box {
    display: block;
  }
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 500;
    font-size: 1.4rem;
    line-height: 2rem;
    text-transform: uppercase;
    margin-bottom: 0.5rem;
  }
  &__message {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.2rem;
    line-height: 1.4rem;
  }
  &__close-icon {
    font-family: $font-primary;
    font-size: 1.4rem;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1rem;
  }
}
.mobile-nav {
  position: fixed;
  background-color: transparent;
  left: 1rem;
  right: 1rem;
  top: 11rem;
  display: none;
  &__icon {
    width: 5rem;
  }
  &__btn {
    position: absolute;
    &--next {
      right: 0;
      transform: rotate(180deg);
    }
    &--prev {
      left: 0;
    }
  }
}
.events {
  padding: 5rem 3rem;
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 5rem;
    line-height: 5rem;
    margin-bottom: 5rem;
    color: $color-text-primary;
  }
}
.event-card {
  position: relative;
  display: flex;
  padding: 3rem 0;
  border-top: 1px solid $color-text-primary;
  &__image-container {
    flex-shrink: 0;
    width: 49.3rem;
    height: 30.7rem;
    margin-right: 3rem;
  }
  &__image {
    object-fit: contain;
    object-position: center left;
    width: 100%;
    height: 100%;
  }
  &__text {
    display: flex;
    flex-direction: column;
    margin-right: 3rem;
    flex: 1;
  }
  &__artist {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.6rem;
    color: $color-text-primary;
  }
  &__event-name {
    &,
    &:active,
    &:visited,
    &:link {
      font-family: $font-primary;
      font-style: normal;
      font-weight: bold;
      font-size: 3rem;
      line-height: 3rem;
      margin: 2rem 0;
      color: $color-text-primary;
      text-decoration: none;
      transition: color 0.2s;
    }
    &:hover {
      color: $color-primary;
    }
    &--disabled:hover {
      color: $color-text-primary;
    }
  }
  &__description {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 2.8rem;
    color: $color-text-primary;
    max-height: 16.8rem;
    overflow: hidden;
    text-overflow: ellipsis;
  }
  &__info {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: $color-text-primary;
    margin-top: auto;
    margin-bottom: 2rem;
  }
  &__location,
  &__price {
    margin-left: 2rem;
  }
  &__cta {
    display: flex;
    flex-direction: row;
    align-items: flex-end;
  }
  &__see-more {
    &:active,
    &:visited,
    &:link,
    &:hover {
      color: $color-primary;
      font-family: $font-primary;
      font-style: normal;
      font-weight: bold;
      font-size: 1.4rem;
      line-height: 2.8rem;
      margin-right: 3rem;
      text-transform: uppercase;
      text-decoration: none;
    }
    &--mobile {
      display: none;
    }
  }
  &__btn {
    width: 20.7rem;
    height: 6rem;
    text-decoration: none;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  &__share-area {
    position: absolute;
    right: 2rem;
    top: 3rem;
  }
  &__share-icon {
    width: 2.9rem;
    height: 3.2rem;
    cursor: pointer;
  }
  .share-list {
    right: 0;
    min-width: 16rem;
  }
}
.event-summary {
  padding: 2rem;
  background-color: $color-bg-secondary;
  /* box-shadow: 0px 0px 7px 3px rgba(0, 0, 0, 0.2); */
  border-radius: 6px;
  margin-bottom: 2.5rem;
  display: flex;
  &__image-container {
    flex: 0 0 19.3rem;
    height: 11.2rem;
  }
  &__image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
  &__body {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }
  &__artist,
  &__event-name {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.8rem;
    line-height: 1.8rem;
    color: $color-text-primary;
  }
  &__event-info {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: $color-text-primary;
  }
  &__event-time {
    display: none;
  }
  &__action-area {
    display: flex;
  }
  &__action:not(:last-child) {
    margin-right: 2rem;
  }
  &__action,
  &__action:link,
  &__action:visited,
  &__action:hover,
  &__action:active {
    display: flex;
    align-items: center;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.4rem;
    line-height: 1.4rem;
    color: $color-tertiary;
    text-decoration: none;
    cursor: pointer;
    white-space: nowrap;
  }
  &__action-icon {
    margin-right: 0.5rem;
    flex: 0 0 2rem;
    height: 2rem;
    path {
      fill: $color-tertiary;
      stroke: $color-bg;
    }
    circle {
      fill: $color-tertiary;
    }
  }
  &__popup-image {
    width: 100%;
    height: 100%;
    object-fit: contain;
  }
}
.terms {
  color: $color-text-primary;
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.5rem;
    position: relative;
    margin: 1.5rem 0;
    color: $color-text-primary;
    &::after {
      content: "*";
      color: red;
      position: absolute;
      font-size: 2rem;
    }
  }
  &__body {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.8rem;
    max-height: 12rem;
    overflow-y: auto;
    &--title {
      margin-bottom: 1rem;
    }
    &::-webkit-scrollbar {
      width: 3px;
      margin-right: 10px;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      background: transparent;
      border-radius: 100px;
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      background: $color-grey-4;
      border-radius: 100px;
    }

    /* Handle on hover */
    &::-webkit-scrollbar-thumb:hover {
      background: $color-grey-3;
    }
  }
  &__btn {
    margin-top: 1rem;
    margin-bottom: 1rem;
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem !important;
    line-height: 1.6rem !important;
  }
  .error-message {
    margin-bottom: 1rem;
  }
}
.help-page {
  display: flex;
  height: calc(100vh - #{$navbar-height});
  height: calc((var(--vh, 1vh) * 100) - #{$navbar-height});
  &__title-container {
    padding: 5rem 5rem 0 5rem;
    flex: 1;
    position: relative;
  }
  &__content {
    padding: 5rem 5rem 0 5rem;
    flex: 1;
    height: 100%;
    overflow-y: auto;
    padding-bottom: 2rem;
  }
  &__title {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 10rem;
    line-height: 12rem;
    color: $color-primary;
    @supports (
      (background-clip: text) and
        (
          background-image:
            linear-gradient(to right, $color-primary, $color-grey-2)
        )
    ) {
      background-image: linear-gradient(
        to right,
        $color-primary 35%,
        $color-grey-2 80%
      );
      background-clip: text;
      color: transparent;
    }
  }
}
.faq {
  position: relative;
  border-bottom: 1px solid $color-text-primary;
  padding: 2rem 0;
  cursor: pointer;
  color: $color-text-primary;
  &__title {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  &__question {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 1.6rem;
    line-height: 2.5rem;
    width: calc(100% - 3rem);
  }
  &__answer {
    height: 0;
    overflow: hidden;
    font-family: $font-primary;
    font-style: normal;
    font-weight: 300;
    font-size: 1.6rem;
    line-height: 1.8rem;
    padding-top: 0;
    transition: padding-top 0.2s;
    white-space: pre-wrap;
    &--expanded {
      height: unset;
      padding-top: 2rem;
    }
    color: $color-grey-3;
  }
  &__icon {
    width: 2.1rem;
    transition: transform 0.2s;
    &--expanded {
      transform: rotateX(180deg);
    }
    path {
      fill: $color-text-primary;
    }
  }
}
.terms-of-purchase {
  &__paragraph {
    margin-bottom: 3rem;
    &--line2 {
      margin-bottom: 1.5rem;
    }
  }
  &__links-list {
    list-style-position: inside;
    margin-bottom: 3rem;
  }
  &__link-item {
    margin-bottom: 1rem;
  }
  &__link {
    color: #3468c2;
    text-decoration: none;
    font-weight: 500;
    display: inline-block;
    cursor: pointer;
  }
  &__title {
    font-weight: 700;
    margin-bottom: 1rem;
  }
}
.event-info-popup {
  width: 95%;
  background-color: $color-bg;
  border: 1px solid $color-bg;
  .event-page {
    &__event-image-text {
      left: 8rem;
    }
  }
  .cta-popup {
    display: none;
  }
  .popup__close {
    path {
      fill: $color-text-primary;
    }
  }
}
.mobile-header {
  display: none;
}
.guests {
  width: calc(100% - 11rem);
  position: relative;
  margin-top: 2rem;
  padding: 2rem 6rem 2rem 4rem;
  color: $color-text-primary;
  user-select: none;
  .guests__select-input__menu {
    color: $color-text-secondary;
  }
  &__select-input {
    font-family: $font-primary;
    &--error,
    &--success {
      .guests__select-input__indicators {
        visibility: hidden;
      }
    }
    &__menu {
      color: $color-text-tertiary !important;
    }
    &__control--is-disabled {
      background-color: hsl(0, 0%, 85%) !important;
      .guests__select-input__input-container {
        color: rgb(90, 90, 90) !important;
      }
      .guests__select-input__single-value {
        color: rgb(90, 90, 90) !important;
      }
    }
    &__indicator {
      padding-top: 0 !important;
      padding-bottom: 0 !important;
    }
  }
  .registration-form__input {
    height: 4.4rem;
  }
  .registration-form__input-group {
    margin-bottom: 3rem;
  }
  input:disabled,
  button:disabled {
    background-color: hsl(0, 0%, 85%) !important;
    color: rgb(90, 90, 90) !important;
  }
  .PhoneInputInput {
    height: 4rem;
    font-size: 1.6rem;
    font-family: $font-primary;
  }
  .PhoneInput {
    border-radius: 0;
    height: 4.4rem;
    background-color: white;
    color: black;
  }
  &__row {
    padding-top: 1rem;
    .guests__expand {
      margin-left: auto;
    }
  }
  &__row,
  &__collapsed-row {
    border-bottom: 1px solid $color-grey-1;
    position: relative;
  }
  &__title {
    font-family: $font-primary;
    font-style: normal;
    font-weight: bold;
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-bottom: 1rem;
    display: flex;
    align-items: center;
    cursor: pointer;
  }
  &__collapsed-row {
    display: flex;
    padding: 2rem 0;
    align-items: center;
    cursor: pointer;
    .guests__title {
      margin-bottom: 0;
      width: 10rem;
    }
  }
  &__name,
  &__ticket {
    font-family: $font-primary;
    font-style: normal;
    font-weight: normal;
    font-size: 1.6rem;
    line-height: 1.9rem;
    flex: 1;
    display: flex;
    justify-content: flex-end;
    padding-left: 2rem;
  }
  &__expand-icon {
    width: 1.9rem;
    &--expanded {
      transform: rotateX(180deg);
    }
    path {
      fill: $color-text-primary;
    }
  }
  &__expand {
    cursor: pointer;
    margin-left: 3rem;
  }
  &__status-icon {
    &--error {
      path {
        fill: $danger;
      }
    }
    &--success {
      path {
        fill: $success;
      }
    }
  }
  &__delete {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid $danger;
    color: $danger;
    position: absolute;
    padding: 1rem 2rem;
    right: -11rem;
    font-size: 1.3rem;
    font-weight: 400;
  }
  &__add-btn {
    font-family: $font-primary;
    user-select: none;
    font-weight: 600;
    font-size: 1.7rem;
    margin-left: 4rem;
    color: $color-primary;
    cursor: pointer;
    display: inline-block;
    font-size: 2rem;
  }
  .registration-form__input-box {
    font-size: 1.6rem;
    height: 4.4rem;
  }
}

.forgot-password-page {
  position: relative;
  width: 50rem;
  margin: auto;
  &__form {
    display: flex;
    flex-direction: column;
  }
  &__paragraph {
    font-size: 1.6rem;
    color: $color-text-primary;
    font-family: $font-primary;
    margin-bottom: 2rem;
  }
  .registration-form__title {
    color: $color-primary;
    margin-bottom: 1rem;
  }
  .registration-form__label {
    color: $color-text-primary;
  }
  .registration-form__btn {
    align-self: center;
  }
}
.success-message {
  color: $success;
}
.presales-page {
  display: flex;
  height: calc(100vh - #{$navbar-height} - #{$ctapopup-height});
  height: calc(
    (var(--vh, 1vh) * 100) - #{$navbar-height} - #{$ctapopup-height}
  );
  justify-content: space-between;

  &__error {
    font-size: 4rem;
    color: $danger;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    margin-top: 2rem;
    margin-left: 2rem;
  }
  &__success {
    font-size: 4rem;
    font-family: $font-secondary;
    font-style: normal;
    font-weight: 500;
    margin-top: 2rem;
    margin-left: 2rem;
  }
  &__guests-area {
    width: 60%;
    overflow-y: auto;
    margin-top: 4rem;
    margin-right: 1rem;
    margin-left: 4rem;
  }
  .guests {
    width: calc(100% - 13rem);
    padding-top: 0;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    &__row:first-child {
      padding-top: 0;
    }
  }
  .checkout__details {
    margin-top: 4rem;
    margin-right: 4rem;
  }
  .checkout__subtitle {
    margin-bottom: 2rem;
  }
  .page-title {
    font-family: $font-secondary;
    font-weight: 500;
    font-style: normal;
    margin-bottom: 2rem;
  }
  .checkout__order-actions {
    display: none;
  }
  .event-summary {
    &__action {
      display: none;
    }
    &__event-time {
      display: none;
    }
  }
  .guests__add-btn {
    margin-left: 0;
  }
  &__description {
    color: $color-text-primary;
    font-family: $font-primary;
    font-size: 2rem;
    margin-bottom: 2rem;
  }
  &__ticket-selector {
    width: 60%;
    overflow-y: auto;
    padding: 4rem;
  }
}

.payment-popup {
  background-color: white;
  padding-top: 0;
  padding-bottom: 0;
  .popup__close {
    path {
      fill: black;
    }
  }
}
.payment-methods-list {
  .payment-methods-list {
    padding-left: 5rem;
  }
}
.reset-password-page {
  margin-top: 10rem;
  padding: 5rem;
  width: 60rem;
  background-color: $color-bg-secondary;
  border-radius: 4px;
}
.profile {
  display: flex;
  width: 80%;
  margin: auto;
  height: calc(100vh - #{$navbar-height});
  padding: 2rem 3rem;
  align-items: flex-start;
  overflow-y: auto;
  &__side-bar {
    flex: 2;
    margin-right: 3rem;
    background-color: $color-bg-secondary;
    border-radius: 4px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 2.5rem 3rem;
    overflow-y: auto;
  }
  &__body {
    flex: 5;
    background-color: $color-bg-secondary;
    border-radius: 4px;
    padding: 3rem 5rem;
  }
  &__picture-container {
    width: 20rem;
    height: 20rem;
    margin-bottom: 2.5rem;
  }
  &__picture {
    object-fit: cover;
    width: 100%;
    height: 100%;
    background-color: transparent;
    border-radius: 50%;
    path {
      fill: $color-grey-3;
    }
  }
  &__user-name {
    color: $color-text-primary;
    font-family: $font-primary;
    font-size: 2.8rem;
    line-height: 2.8rem;
    font-weight: 500;
    margin-bottom: 2.5rem;
  }
  &__tabs {
    display: flex;
    flex-direction: column;
    width: 100%;
  }
  &__tab-item {
    display: flex;
    align-items: center;
    border-radius: 100px;
    padding: 1rem;
    cursor: pointer;
    transition: all 0.3s;
    &:not(:last-child) {
      margin-bottom: 1rem;
    }
    &--active {
      background-color: $color-primary;
      .profile__tab-item-label {
        color: $color-bg;
      }
      .profile__tab-item-icon {
        path {
          fill: $color-bg;
        }
      }
    }
  }
  &__tab-item-icon-container {
    width: 3rem;
    height: 3rem;
  }
  &__tab-item-icon {
    width: 100%;
    height: 100%;
    path {
      fill: $color-primary;
    }
  }
  &__tab-item-label {
    color: $color-primary;
    font-family: $font-primary;
    font-weight: 400;
    font-size: 2.4rem;
    line-height: 2.4rem;
    margin-left: 2rem;
  }
  &__form-btn-container {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;
    align-items: center;
  }
  &__form-btn {
    padding: 1rem 2.5rem;
  }
  &__form-message {
    font-family: $font-primary;
    font-size: 1.6rem !important;
    line-height: 1.6rem !important;
    margin-right: 1rem;
  }
}
//CUSTOM CHECKBOX CSS
/* Customize the label (the container) */
.custom-checkbox {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  cursor: pointer;
  font-size: 2.2rem;
  user-select: none;
}

/* Hide the browser's default checkbox */
.custom-checkbox input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}

/* Create a custom checkbox */
.custom-checkbox .checkmark {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  height: 2rem;
  width: 2rem;
  border: 1px solid $color-text-primary;
  border-radius: 50%;
  background-color: white;
}
.custom-checkbox--square .checkmark {
  border-radius: 2px;
}

/* When the checkbox is checked, add a blue background */
.custom-checkbox input:checked ~ .checkmark {
  background-color: $color-bg;
  border: 1px solid $color-primary;
}

/* Create the checkmark/indicator (hidden when not checked) */
.custom-checkbox .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the checkmark when checked */
.custom-checkbox input:checked ~ .checkmark:after {
  display: block;
}

/* Style the checkmark/indicator */
.custom-checkbox .checkmark:after {
  left: 50%;
  top: 50%;
  width: 0.5rem;
  height: 1rem;
  border: solid $color-bg;
  border-width: 0 1px 1px 0;
  transform: translateY(-70%) translateX(-50%) rotate(45deg);
}

//CUSTOM RADIO
.custom-radio {
  display: block;
  position: relative;
  padding-left: 3.5rem;
  font-size: 2.2rem;
  cursor: pointer;
  user-select: none;
}

/* Hide the browser's default radio button */
.custom-radio input {
  position: absolute;
  opacity: 0;
  cursor: pointer;
}

/* Create a custom radio button */
.custom-radio .checkmark {
  position: absolute;
  top: 50%;
  left: 0;
  transform: translateY(-50%);
  height: 2rem;
  width: 2rem;
  background-color: white;
  border-radius: 50%;
  border: 1px solid $color-primary;
}

/* When the radio button is checked, add a blue background */
.custom-radio input:checked ~ .checkmark {
  background-color: white;
  border: 1px solid $color-primary;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.custom-radio .checkmark:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.custom-radio input:checked ~ .checkmark:after {
  display: block;
}

/* Style the indicator (dot/circle) */
.custom-radio .checkmark:after {
  top: 50%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  width: 1.4rem;
  height: 1.4rem;
  border-radius: 50%;
  background: $color-bg;
}

.privacy-policy {
  color: $color-text-primary;
  font-family: $font-primary;
  padding: 2rem 4rem;
  h2 {
    font-size: 3rem;
    line-height: 5rem;
    margin-bottom: 2rem;
  }
  p {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }
  b {
    font-weight: 600;
  }
}

.seat-details {
  &__button {
    padding: 1rem 1.5rem;
    border: 1px solid $color-primary;
    border-radius: 12px;
    font-size: 2rem;
    line-height: 2rem;
    display: inline-block;
    font-family: $font-primary;
  }
  &__button-text {
    display: inline-block;
    margin-right: 2rem;
    color: $color-text-primary;
  }
  &__button-count {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    width: 4rem;
    height: 4rem;
    border-radius: 50%;
    background-color: $color-tertiary;
    color: $color-text-secondary;
  }
  &__popup {
    overflow-y: auto;
  }
  &__popup-header {
    border-bottom: 1px solid $color-grey-2;
    padding-bottom: 3rem;
  }
  &__seats-table {
    margin-top: 3rem;
    tr:last-child {
      td {
        border: none;
      }
    }

    td {
      padding: 1.5rem 1rem;
    }
    th {
      padding-left: 1rem;
      padding-right: 1rem;
    }
  }
  &__seats-delete-icon {
    width: 1.5rem;
    height: 1.5rem;
    cursor: pointer;
    path {
      fill: $color-text-primary;
    }
  }
  &__table-last-row {
    td {
      border: none;
    }
  }
}

.empty-page {
  width: 100%;
  height: calc(100vh - #{$navbar-height});
  height: calc((var(--vh, 1vh) * 100) - #{$navbar-height});
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 10rem;
  &__message {
    font-family: $font-secondary;
    font-weight: 400;
    font-size: 3.2rem;
    line-height: 3.8rem;
    text-align: center;
    color: $color-text-primary;
  }
  &__link {
    &:link,
    &:visited,
    &:hover,
    &:active {
      color: $color-text-primary;
    }
  }
}
.page-loader-container {
  width: 100%;
  height: calc(100vh - #{$navbar-height});
  height: calc((var(--vh, 1vh) * 100) - #{$navbar-height});
  display: flex;
  align-items: center;
  justify-content: center;
}

.payment-selector {
  &__header{
    width: 100%;
    font-family: $font-primary;
    font-size: 2rem;
    color: $color-text-primary;
  }
  &__body {
    display: flex;
    flex-direction: column;
    align-items: center;
    .payment-methods-list {
      width: 100%;
      margin-bottom: 4rem;
    }
  }
  &__btn {
    font-family: $font-primary;
    font-style: normal;
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.4rem;
    height: 5.7rem;
    width: 38rem;
    margin-bottom: 2rem
  }
}
@import "responsiveness";
